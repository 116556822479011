import React from 'react'
import {
  connect,
  mapStateToProps,
  PaginationDiv,
  SpinnerLoadFirst,
  SpinnerButton,
  httpBuildQuery,
  moment,
  axiosCompany,
  getDivisionClientVisit,
  checkAuthorization
} from '../../helpers/Common'
import {
  Search, Division,
  ExportExcel,
  LangEmployee,
  LangPayrollDateRangeInfo,
  LangVisitSummary,
  LangClientVisit,
  LangVisitScheduleTotal,
  LangVisitTotal,
  LangVisitPercentage
} from '../../constant/languange'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { InputRangeDatePicker, ModalApp } from '../../helpers/Input'
import BreadcrumbsMenu from '../../layout/breadcrumb/breadcrumbMenu'
import { Modal } from 'react-bootstrap'
import VisitSummaryExportComponent from './VisitSummaryExportComponent'
import PersonnelItemComponent from '../personnel/PersonnelItemComponent'
import VisitSummaryViewDatesComponent from './VisitSummaryViewDatesComponent'

class VisitSummaryComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      dataGroup: [],
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      showAlert: '',
      groupId: '',
      activePage: 1,
      search: '',
      loading: false,
      loading2: false,
      onExport: false,
      dataResponse: {
        data: [],
        meta: {
          total: 0
        }
      },
      modal: {
        show: false,
        title: '',
        body: ''
      },
      showModal: '',
      error: {}
    }

    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleExportExcel = this.handleExportExcel.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
  }

  componentDidMount () {
    this.loadData()

    if (this.props.redux?.groups) {
      // this.setState({
      //   dataGroup: this.props.redux.groups
      // })
      getDivisionClientVisit().then(data => this.setState({ dataGroup: data }))
    }
  }

  handlePageChange (pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.loadData()
    })
  }

  handleExportExcel () {
    this.setState({ onExport: true })

    /**
     * format date input ke UTC
     */
    const filter = {
      group_id: this.state.groupId,
      date_from: this.state.startDate,
      date_to: this.state.endDate,
      keyword: this.state.search
    }

    axiosCompany()
      .get('attendances/summaries-xlsx?' + httpBuildQuery(filter), {
        responseType: 'blob'
      })
      .then((result) => {
        const url = window.URL.createObjectURL(new window.Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `attendance-summaries-${this.state.startDate}-to-${this.state.endDate}.xlsx`)
        document.body.appendChild(link)
        link.click()

        this.setState({ onExport: false })
      })
  }

  loadData = () => {
    this.setState({ loading: true })

    const filter = {
      page: this.state.activePage,
      group_id: this.state.groupId,
      creation_date_end: this.state.endDate,
      keyword: this.state.search,
      order_by: 'name',
      order_direction: 'asc',
      with_trashed: '1',
      is_active_client_visit: 1
    }

    axiosCompany().get('personnels?' + httpBuildQuery(filter))
      .then((res) => {
        this.setState({
          dataResponse: res.data
        }, () => {
          this.state.dataResponse.data.forEach((obj) => {
            this.setState({ [`dataSummaries${obj.id}`]: null })
            axiosCompany().get(`client-visits/summaries/${obj.id}?date_from=${this.state.startDate}&date_to=${this.state.endDate}`)
              .then((res) => {
                this.setState({ [`dataSummaries${obj.id}`]: res.data })
              })
              .catch(err => {
                this.setState({ [`dataSummaries${obj.id}`]: err.response.data })
              })
          })
        })
        // this.setState({ dataResponse: { ...this.state.dataResponse, data: [...this.state.dataResponse.data.slice(0, 5)] } })
        // this.setState({ dataResponse: { ...this.state.dataResponse, data: [...this.state.dataResponse.data, ...this.state.dataResponse.data] } })
        this.setState({ loading: false, loading2: false })
      })
      .catch(err => {
        this.setState({ error: err.response.data, loading: false, loading2: false })
      })
  }

  handleGroup = (e) => {
    this.setState({
      groupId: e,
      activePage: 1
    }, () => {
      this.loadData()
    })
  }

  handleStartDate = (e) => {
    this.setState({
      startDate: e,
      activePage: 1
    }, () => {
      this.loadData()
    })
  }

  handleEndDate = (e) => {
    this.setState({
      endDate: e,
      activePage: 1
    }, () => {
      this.loadData()
    })
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter' && !this.state.loading) {
      this.setState({ loading2: true, activePage: 1 })
      this.loadData()
    }
  }

  handleDateChange (value) {
    /**
     * cek apakah lebih dari 60 hari, jika ya paksa maks 60 hari
     */
    let startDate = value.startDate
    let showAlert = ''
    const endDateSub = moment(value.endDate).subtract(2, 'month')
    if (moment(value.startDate).isBefore(endDateSub)) {
      startDate = endDateSub.format('YYYY-MM-DD')
      showAlert = 'date-range'
    }

    this.setState({
      showAlert,
      startDate,
      endDate: value.endDate,
      activePage: 1
    }, () => {
      this.loadData()
    })
  }

  handleViewDates (e, dataModal) {
    e.preventDefault()
    this.setState({ showModal: 'view-dates', dataModal })
  }

  showModalDescription (title, body) {
    this.setState({ modal: { show: true, title, body } })
  }

  render () {
    const p = this.props

    return (
      <>
        <BreadcrumbsMenu title={LangVisitSummary}>{[LangClientVisit, LangVisitSummary]}</BreadcrumbsMenu>

        {(this.state.showModal === 'export')
          ? (
            <ModalApp
              title={ExportExcel}
              handleClose={() => this.setState({ showModal: '' })}
              body={
                <VisitSummaryExportComponent
                  handleClose={() => this.setState({ showModal: '' })}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  groupId={this.state.groupId}
                  groups={this.state.dataGroup}
                />
            }
            />
            )
          : null}

        {(this.state.showModal === 'view-dates')
          ? (
            <ModalApp
              title={this.state.dataModal.title}
              handleClose={() => this.setState({ showModal: '' })}
              size='md'
              body={<VisitSummaryViewDatesComponent data={this.state.dataModal.dates} id={this.state.dataModal.personnelId} />}
            />
            )
          : null}

        <div className='container-fluid '>
          <div className='row '>
            <div className='col-sm-12 '>
              <div className='card'>
                <div className='card-header'>
                  <div className='d-flex flex-column flex-xl-row flex-xls-column'>
                    <div className='d-md-flex'>
                      <div className='mr-2 mb-2' style={{ maxWidth: '330px' }}>
                        <InputRangeDatePicker
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          onChange={this.handleDateChange}
                        />
                        {this.state.showAlert === 'date-range' ? (<div className='alert alert-info outline small py-1 px-2 mt-2 mb-0'>{LangPayrollDateRangeInfo}</div>) : null}
                      </div>
                      <div className='d-sm-flex'>
                        <div className='mr-2 mb-2'>
                          <select className='form-control' style={{ maxWidth: 250 }} onChange={(e) => this.handleGroup(e.target.value)}>
                            <option value=''>--{Division}--</option>
                            {this.state.dataGroup.map((obj) => {
                              return (
                                <option key={obj.id} value={obj.id}>{obj.name}</option>
                              )
                            })}
                          </select>
                        </div>
                        <div className='mb-2'>
                          <div className='input-group'>
                            <input
                              type='search' className='form-control' placeholder={Search} onKeyDown={this._handleKeyDown} onChange={(e) => this.setState({ search: e.target.value })} aria-label='Search'
                              aria-describedby='search-addon' style={{ maxWidth: 250 }}
                            />
                            <div className='input-group-append' style={{ cursor: 'pointer' }} onClick={() => this.setState({ loading2: true }, () => this.loadData())}><span className={`input-group-text ${p.redux.darkModeOn ? 'bg-dark border-dark' : 'bg-light text-dark'}`}><i> {this.state.loading2 ? SpinnerButton : <FontAwesomeIcon icon={faSearch} />} </i></span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='ml-xl-auto ml-xls-none'>
                      {checkAuthorization(p.redux, 'client-visit.summary.export') && (
                        <button onClick={() => this.setState({ showModal: 'export' })} disabled={this.state.onExport || this.state.loading || this.state.loading2} type='button' className='btn btn-primary'><FontAwesomeIcon icon={faFileDownload} />{(this.state.onExport) ? SpinnerButton : null} {ExportExcel}</button>
                      )}
                    </div>
                  </div>
                </div>
                {this.state.loading
                  ? SpinnerLoadFirst
                  : (
                    <div className='table-responsive' style={{ maxHeight: '55vh' }}>
                      <table className='table table-striped table-bordered table-border-separate'>
                        <thead>
                          <tr>
                            <th className={`${p.redux.darkModeOn ? 'bg-dark' : ''} table-column-sticky-left th-sticky-corner text-center align-middle`} scope='col' style={{ minWidth: '300px' }}>{LangEmployee}</th>
                            <th className={`${p.redux.darkModeOn ? 'bg-dark' : ''} table-th-sticky text-center align-middle`} scope='col' style={{ minWidth: '200px' }}>{LangVisitScheduleTotal}</th>
                            <th className={`${p.redux.darkModeOn ? 'bg-dark' : ''} table-th-sticky text-center align-middle`} scope='col' style={{ minWidth: '120px' }}>{LangVisitTotal}</th>
                            <th className={`${p.redux.darkModeOn ? 'bg-dark' : ''} table-th-sticky text-center align-middle`} scope='col' style={{ minWidth: '120px' }}>{LangVisitPercentage}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.dataResponse.data.map((item, i) => {
                            const summary = this.state[`dataSummaries${item.id}`]

                            const oddColor = p.redux.darkModeOn ? '#2c323f' : 'white'
                            const evenColor = p.redux.darkModeOn ? '#373c49' : 'rgba(245, 247, 250, 1)'
                            return (
                              <tr key={item.id}>
                                <td className='table-column-sticky-left' style={{ backgroundColor: (i % 2 !== 0 ? oddColor : evenColor) }}>
                                  <PersonnelItemComponent data={item} key={'personnel-' + item.id} />
                                </td>
                                {summary !== null && summary.message ? (
                                  <td colSpan={3} style={{ textAlign: 'center', color: 'red' }}>{summary.message}</td>
                                ) : (
                                  <>
                                    <td className='text-center align-center'>
                                      {(summary === null) ? 
                                        SpinnerButton 
                                        : 
                                        checkAuthorization(p.redux, 'client-visit.index') ?
                                          <a href='#' onClick={(e) => this.handleViewDates(e, { title: LangVisitScheduleTotal, dates: summary.schedules, personnelId: item.id })}>{summary.schedules_total}</a>
                                          :
                                          <span>{summary.schedules_total}</span>
                                      }
                                    </td>
                                    <td className='text-center align-center'>
                                      {(summary === null) ? 
                                        SpinnerButton 
                                        :
                                        checkAuthorization(p.redux, 'client-visit.index') ?
                                          <a href='#' onClick={(e) => this.handleViewDates(e, { title: LangVisitTotal, dates: summary.client_visited, personnelId: item.id })}>{summary.client_visited_total}</a>
                                          :
                                          <span>{summary.client_visited_total}</span>
                                      }
                                    </td>
                                    <td className='text-center align-center'>{(summary === null) ? SpinnerButton : summary.client_visited_percentage}</td>
                                  </>
                                )}
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                    )}

                <div className='card-footer'>
                  <PaginationDiv data={this.state.dataResponse} onChange={this.handlePageChange} />
                  {this.state.error?.message && <div class='alert alert-danger my-2 w-100'>{this.state.error?.message}</div>}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          animation={false}
          show={this.state.modal.show}
          keyboard={false}
          onHide={() => { this.setState({ modal: { ...this.state.modal, show: false } }) }}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modal.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.modal.body}
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default connect(mapStateToProps)(VisitSummaryComponent)
