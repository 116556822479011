import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Close, LangAutoAddToday, LangAutoAdded, LangAutomaticRenewalPeriod, LangFirstGetAnnualLeave, LangFirstThing, LangImportCancel, LangMonth, LangNo, LangNoPrevSettings, LangOnWhen, LangPlease, LangProcess, LangRemainingLeave, LangSetWorkStartDate, LangStartSetup, LangStartWorkOn, LangSuccess, LangTurnOnSettings, LangYes } from '../../constant/languange'
import { axiosCompany, checkAuthorization, displayDate, mapDispatchToProps, mapStateToProps, SpinnerLoadFirst } from '../../helpers/Common'
import { SpinnerButton } from '../../helpers/Spinner'
import moment from 'moment'

function PersonnelLeaveUpdateComponent ({ redux, setState, currentData, getPersonnelsService, ...props }) {
  const initData = { period_month: 12, previous_annual_leave: 'add', enabled: 1 }

  const [errors, setError] = useState({ fields: [], message: '' })
  const [control, setControl] = useState({ showLoad: false })
  const [data, setData] = useState(initData)
  const [loadData, setLoadData] = useState(false)
  const [notSettingYet, setNotSettingYet] = useState(false)
  const [autoReloadDate, setAutoReloadDate] = useState({ next_period: null })

  useEffect(() => {
    getDataService()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getAutoReloadDate()
    // eslint-disable-next-line
  }, [data.period_month])

  const getDataService = () => {
    setLoadData(true)
    axiosCompany().get(`/personnels/${currentData.id}/auto-reload-annual-leave`)
      .then(res => {
        if (Array.isArray(res.data) && res.data.length === 0) setNotSettingYet(true)
        setLoadData(false)
        setData({ ...data, ...res.data })
      })
      .catch(() => {
        setLoadData(false)
      })
  }

  const getAutoReloadDate = () => {
    axiosCompany().get(`/personnels/${currentData.id}/auto-reload-annual-leave-check-next-period`, { params: { period_month_setting: data.period_month } })
      .then(res => {
        setAutoReloadDate(res.data)
      })
  }

  const postDataService = (e) => {
    e.preventDefault()

    setControl({ ...control, showLoad: true, success: false })
    axiosCompany().post(`/personnels/${currentData.id}/auto-reload-annual-leave`, { ...data, id: currentData.id })
      .then(res => {
        setError({ fields: [], message: '' })
        setControl({ ...control, showLoad: false, success: true })
        if (typeof getPersonnelsService === 'function') getPersonnelsService()
      }).catch(e => {
        setError(error => {
          error.fields = e.response.data.errors ? e.response.data.errors : { 0: [e.response.statusText] }
          error.message = e.response.data.message ? e.response.data.message : ''
          return { ...error }
        })
        setControl({ ...control, showLoad: false })
      })
  }

  return (
    <>
      {loadData
        ? SpinnerLoadFirst
        : (
          <>
            {!currentData.start_work
              ? (
                <div className={`alert ${redux.darkModeOn ? 'alert-dark' : 'alert-light'}`}>
                  <p>{LangPlease} <button className='btn btn-link p-0 border-0 text-info' onClick={props.handleEdit}>{LangSetWorkStartDate}</button> {LangFirstThing}</p>
                </div>
                )
              : (
                <>
                  {notSettingYet
                    ? (
                      <div className={`alert ${redux.darkModeOn ? 'alert-dark' : 'alert-light'}`}>{LangNoPrevSettings} {checkAuthorization(redux, 'employee.update') && (<button className='btn btn-link p-0 text-info' onClick={() => setNotSettingYet(false)}>{LangStartSetup}</button>)}</div>
                      )
                    : (
                      <>
                        <div className={`alert ${redux.darkModeOn ? 'alert-dark' : 'alert-light'}`}>
                          <b>Info</b>
                          <p>{LangStartWorkOn} {displayDate(currentData.start_work, 'DD MMM YYYY')}, {LangFirstGetAnnualLeave} {displayDate(moment(currentData.start_work).add(1, 'years').format('YYYY-MM-DD'), 'DD MMM YYYY')}.</p>

                        </div>

                        <form className='form' onSubmit={(e) => { postDataService(e) }} autoComplete='off'>
                          <div className='form-group row'>
                            <label className='col-sm-4 col-form-label text-lg-right'>{LangAutomaticRenewalPeriod}</label>
                            <div className='col-sm-8'>
                              <select disabled={!checkAuthorization(redux, 'employee.update')} className='form-control' onChange={(e) => setData({ ...data, period_month: e.target.value })} value={+data.period_month}>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                                  <option key={item} value={item}>{item} {LangMonth}</option>
                                ))}
                              </select>
                              <div className={`alert ${redux.darkModeOn ? 'alert-dark' : 'alert-light'} px-2 py-1 mt-2`}>
                                <b>Info</b>
                                <p>{LangAutoAdded} {+data.period_month} {autoReloadDate.next_period ? `${LangOnWhen} ${displayDate(autoReloadDate.next_period, 'DD MMM YYYY')}` : ''}.</p>
                              </div>
                              {moment().format('YYYY-MM-DD') === autoReloadDate.next_period && (
                                <div className='alert alert-warning text-dark px-2 py-1 mt-2'>
                                  <p>{LangAutoAddToday}</p>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='form-group row'>
                            <label className='col-sm-4 col-form-label text-lg-right'>{LangRemainingLeave}</label>
                            <div className='col-sm-8'>
                              <select disabled={!checkAuthorization(redux, 'employee.update')} className='form-control' onChange={(e) => setData({ ...data, previous_annual_leave: e.target.value })} value={data.previous_annual_leave}>
                                {['add', 'remove'].map((item) => (
                                  <option key={item} value={item}>{item === 'add' ? 'Tambahkan' : 'Hapus'}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className='form-group row'>
                            <label className='col-sm-4 col-form-label text-lg-right'>{LangTurnOnSettings}</label>
                            <div className='col-sm-8 d-flex align-items-center'>
                              <div className='form-check form-check-inline'>
                                <input disabled={!checkAuthorization(redux, 'employee.update')} className='form-check-input' type='checkbox' id='showsecondattendant1' checked={+data.enabled === 1} onChange={(e) => setData({ ...data, enabled: 1 })} />
                                <label className='form-check-label' htmlFor='showsecondattendant1'>{LangYes}</label>
                              </div>
                              <div className='form-check form-check-inline'>
                                <input disabled={!checkAuthorization(redux, 'employee.update')} className='form-check-input' type='checkbox' id='showsecondattendant2' checked={+data.enabled === 0} onChange={(e) => setData({ ...data, enabled: 0 })} />
                                <label className='form-check-label' htmlFor='showsecondattendant2'>{LangNo}</label>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className='form-group row'>
                            <div className='col-sm-8 offset-md-4'>
                              {errors.message ? <div className='alert alert-danger'>{errors.message}</div> : null}
                              {control.success ? <div className='alert alert-success'>{LangSuccess}</div> : null}
                              <div>
                                {checkAuthorization(redux, 'employee.update') && (
                                  <button disabled={control.showLoad} type='submit' className='btn btn-primary mr-2'>{(control.showLoad) ? SpinnerButton : null} {LangProcess}</button>
                                )}
                                <button disabled={control.showLoad} onClick={() => props.handleClose()} type='button' className='btn btn-light'>{checkAuthorization(redux, 'employee.update') ? LangImportCancel : Close}</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </>
                      )}
                </>

                )}
          </>

          )}
    </>

  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonnelLeaveUpdateComponent)
