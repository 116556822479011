import React, { useState, useEffect } from 'react'
import styles from './Calendar.module.css'
import moment from 'moment'
import { displayDate } from '../../helpers/Common'
import { April, August, December, February, Friday, January, July, June, March, May, Monday, November, October, Saturday, September, Sunday, Thrusday, Tuesday, Wednesday } from '../../constant/languange'

const months = [January, February, March, April, May, June, July, August, September, October, November, December]
const daysOfWeek = [Monday.substring(0, 3).toUpperCase(), Tuesday.substring(0, 3).toUpperCase(), Wednesday.substring(0, 3).toUpperCase(), Thrusday.substring(0, 3).toUpperCase(), Friday.substring(0, 3).toUpperCase(), Saturday.substring(0, 3).toUpperCase(), Sunday.substring(0, 3).toUpperCase()]

const Month = ({ year, month, holiday }) => {
  // empty cells
  const [emptyCells, setEmptyCells] = useState([])
  // days data
  const [daysInMonth, setDaysInMonth] = useState([])
  // holidays data
  const [holidayData, setHolidayData] = useState({ dates: [], details: [] })

  useEffect(() => {
    // update days
    const dayInAMonth = []
    const amountDaysInMonth = new Date(year, month + 1, 0).getDate()
    for (let day = 1; day <= amountDaysInMonth; day++) {
      dayInAMonth.push(day)
    }
    setDaysInMonth(currDaysInMonth => dayInAMonth)

    // update empty cells
    const emptyCellsInAMonth = []
    const startingDay = new Date(year, month, 0).getDay()
    for (let i = 0; i < startingDay; i++) {
      emptyCellsInAMonth.push('')
    }
    setEmptyCells(currEmptyCells => emptyCellsInAMonth)
    // eslint-disable-next-line
  }, [year])

  // filter holiday only for this month
  useEffect(() => {
    setHolidayData(() => {
      const holidayInThisMonth = holiday.filter(hdy => (month + 1) === (moment(hdy.date, 'YYYY-MM-DD').month() + 1))
      const holidayDates = holidayInThisMonth.map(hdym => moment(hdym.date, 'YYYY-MM-DD').date())

      const holidayInThisMonthSorted = holidayInThisMonth.sort((a, b) => (new Date(a.date) - new Date(b.date)))
      const holidayDatesSorted = holidayDates.sort((a, b) => (a - b))

      return { dates: holidayDatesSorted, details: holidayInThisMonthSorted }
    })
    // eslint-disable-next-line
  }, [holiday])

  return (
    <div>
      <div className={styles.calendar}>
        <div className={styles.header}><span className={styles.monthYear}>{months[month]}</span></div>
        <div className={styles.days}>
          {daysOfWeek.map((dayName, idx) => (
            <div key={idx}>{dayName}</div>
          ))}
          {emptyCells.map((emptyCell, idx) => (
            <div key={idx}>{emptyCell}</div>
          ))}
          {daysInMonth.map((day, idx) => {
            const isHoliday = holidayData?.dates.includes(day)
            return (
              <div key={idx} className={isHoliday ? 'text-danger' : ''}>{day}</div>
            )
          })}
        </div>
        <table className={styles.listHolidays}>
          {holidayData?.details.map((detailHd, idx) => (
            <tr key={idx}>
              <td className='text-danger text-nowrap align-top'>{displayDate(detailHd.date, 'D MMMM YYYY')}</td><td className='align-top px-1'>:</td><td>{detailHd.description}</td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  )
}

const Calendar = ({ holiday, currentYear }) => {
  return (
    <div class={styles.calendarContent}>
      {months.map((month, idx) => <Month holiday={holiday} key={idx} year={currentYear} month={+idx} />)}
    </div>
  )
}

export default Calendar
