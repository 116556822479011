import React, { useState, useEffect } from 'react'
import {
  checkAuthorization,
  connect,
  mapStateToProps
} from '../../helpers/Common'

export default connect(mapStateToProps)(({ redux }) => {
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (checkAuthorization(redux, 'unpaid-leave.day.index')) {
      setCount(redux.notifications.permit_request)
    }
  }, [redux.notifications.permit_request])

  if (count <= 0) return <></>

  return (
    <label className='badge badge-warning position-static m-0 py-1' style={{ fontSize: '9px' }}>{count}</label>
  )
})
