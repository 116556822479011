import React from 'react'
import { AttendanceSpot, DailyAttendance, Group, LangApprovalRules, LangAttendanceApproval, LangAttendanceRevision, LangClaimRequest, LangClaimType, LangClient, LangClientVisit, LangClientVisitSchedule, LangCreated, LangDeduction, LangDeleted, LangDeviceApproval, LangDocuments, LangEdited, LangEmployee, LangEmployeeIdentityFile, LangHolidayCalendar, LangJob, LangLateCharge, LangLeave, LangLeaveHourRequest, LangOvertimeSchedule, LangPayroll, LangPayrollBulkAction7, LangPermit, LangPersonnelClaim, LangPintuKerjooAttendanceApproval2, LangPosition, LangProfilePhotoApproval, LangProject, LangSpecialLeave, Shift, ShiftWorkTime, WorkDay, WorkPattern, WorkTime } from './languange'
import { approvalFields, attendanceAttrFields, attendanceEditRequestFields, attendanceFields, broadcastFields, clientVisitFields, deviceApprovalFields, groupAttrFields, groupFields, groupLateFields, holidayFields, leaveRequestHourFields, payrollDeductionFields, permitLeaveFields, personnelAttrFields, personnelFields, shiftPatternFields, spotFields, workPatternFields, workShiftFields, workTimeFields, payrollBonusFields, clientFields, payrollFields, fileIdentityFields, personnelDocumentFields, reimbursementFields, reimbursementPersonnelFields, reimbursementRequestFields, specialLeaveFields, timesheetActivityFields, timesheetProjectFields, overtimeScheduleFields, approvalRuleFields, personnelProfilePictureRequestFields, pintuKerjooApprovalRequestFields, positionFields, clientVisitScheduleFields, leaveRequestDateFields } from './HistoryFieldLanguageConstant'
import { approvalFieldFormat, attendanceEditRequestFieldFormat, attendanceFieldFormat, broadcastFieldFormat, clientVisitFieldFormat, deviceApprovalFieldFormat, groupFieldFormat, groupLateFieldFormat, holidayFieldFormat, leaveRequestHourFieldFormat, payrollDeductionFieldFormat, payrollBonusFieldFormat, permitLeaveFieldFormat, personnelFieldFormat, shiftPatternFieldFormat, spotFieldFormat, workPatternFieldFormat, workShiftFieldFormat, workTimeFieldFormat, clientFieldFormat, payrollFieldFormat, fileIdentityFormat, personnelDocumentFormat, reimbursementFieldFormat, reimbursementPersonnelFieldFormat, reimbursementRequestFieldFormat, specialLeaveFieldFormat, timesheetActivityFieldFormat, timesheetProjectFieldFormat, overtimeScheduleFieldFormat, approvalRuleFieldFormat, personnelProfilePictureRequestFieldFormat, pintuKerjooApprovalRequestFieldsFormat, positionFieldFormat, clientVisitScheduleFieldFormat, leaveRequestDateFieldFormat } from '../components/common/HistoryItemFieldFormat'
import HistoryItemComponent from '../components/common/HistoryItemComponent'

/**
 *
 * activity histories item property for each loggable data
 * attributes contain
 * title: title that display on ui
 * value: name of modules
 * component:  history item component item
 * changing_fields: list of fields from api that want to display to ui
 * obj_attr_fields: list of fields inside attribute index api,
 * formated_fields: formatted fileds; ex: dates, currency, table
 *
 */

export const activityLogsItem = {
  'App\\TenantModels\\ClientVisitClient': {
    title: `${LangClient}`,
    color: '#93DA3F',
    value: 'ClientVisitClient',
    component: <HistoryItemComponent />,
    changing_fields: clientFields,
    formated_fields: clientFieldFormat
  },
  'App\\TenantModels\\Payroll': {
    title: `${LangPayrollBulkAction7}`,
    color: '#E9AE0B',
    value: 'Payroll',
    component: <HistoryItemComponent />,
    changing_fields: payrollFields,
    formated_fields: payrollFieldFormat
  },
  'App\\TenantModels\\Personnel': {
    title: `${LangEmployee}`,
    color: '#02A676',
    value: 'Personnel',
    component: <HistoryItemComponent />,
    changing_fields: personnelFields,
    obj_attr_fields: personnelAttrFields,
    formated_fields: personnelFieldFormat
  },
  'App\\TenantModels\\PersonnelDocument': {
    title: `${LangDocuments}`,
    color: '#74C56E',
    value: 'Document',
    component: <HistoryItemComponent />,
    changing_fields: personnelDocumentFields,
    formated_fields: personnelDocumentFormat
  },
  'App\\TenantModels\\Group': {
    title: `${Group}`,
    color: '#00635A',
    value: 'Group',
    component: <HistoryItemComponent />,
    changing_fields: groupFields,
    obj_attr_fields: groupAttrFields,
    formated_fields: groupFieldFormat
  },
  'App\\TenantModels\\LatePunishmentGroup': {
    title: `${LangLateCharge}`,
    color: '#994c4b',
    value: 'LatePunishmentGroup',
    component: <HistoryItemComponent />,
    changing_fields: groupLateFields,
    formated_fields: groupLateFieldFormat
  },
  'App\\TenantModels\\WorkTime': {
    title: `${WorkTime}`,
    color: '#b5c914',
    value: 'WorkTime',
    component: <HistoryItemComponent />,
    changing_fields: workTimeFields,
    formated_fields: workTimeFieldFormat
  },
  'App\\TenantModels\\ShiftWorkTime': {
    title: `${ShiftWorkTime}`,
    color: '#FF5A33',
    value: 'ShiftWorkTime',
    component: <HistoryItemComponent />,
    changing_fields: workShiftFields,
    formated_fields: workShiftFieldFormat
  },
  'App\\TenantModels\\Spot': {
    title: `${AttendanceSpot}`,
    color: '#F2A71B',
    value: 'Spot',
    component: <HistoryItemComponent />,
    changing_fields: spotFields,
    formated_fields: spotFieldFormat
  },
  'App\\TenantModels\\ShiftWorkPattern': {
    title: `${WorkPattern} ${Shift}`,
    color: '#0099DD',
    value: 'ShiftWorkPattern',
    component: <HistoryItemComponent />,
    changing_fields: shiftPatternFields,
    formated_fields: shiftPatternFieldFormat
  },
  'App\\TenantModels\\WorkPattern': {
    title: `${WorkPattern} ${WorkDay}`,
    color: '#00ABBD',
    value: 'WorkPattern',
    component: <HistoryItemComponent />,
    changing_fields: workPatternFields,
    formated_fields: workPatternFieldFormat
  },
  'App\\TenantModels\\Attendance': {
    title: `${DailyAttendance}`,
    color: '#8F79DB',
    value: 'Attendance',
    component: <HistoryItemComponent />,
    changing_fields: attendanceFields,
    obj_attr_fields: attendanceAttrFields,
    formated_fields: attendanceFieldFormat
  },
  'App\\TenantModels\\Permit': {
    title: `${LangPermit}`,
    color: '#F2A679',
    addtionalProps: { type: 'permit' },
    value: 'Permit',
    component: <HistoryItemComponent />,
    changing_fields: permitLeaveFields,
    formated_fields: permitLeaveFieldFormat
  },
  'App\\TenantModels\\LeaveRequest': {
    title: `${LangLeave}`,
    color: '#e6c483',
    addtionalProps: { type: 'leaverequest' },
    value: 'LeaveRequest',
    component: <HistoryItemComponent />,
    changing_fields: permitLeaveFields,
    formated_fields: permitLeaveFieldFormat
  },
  'App\\TenantModels\\LeaveRequestDate': {
    title: `${LangLeave}`,
    color: '#e6c483',
    value: 'LeaveRequestDate',
    component: <HistoryItemComponent />,
    changing_fields: leaveRequestDateFields,
    formated_fields: leaveRequestDateFieldFormat
  },
  'App\\TenantModels\\AttendanceEditRequest': {
    title: `${LangAttendanceRevision}`,
    color: '#C13BE0',
    value: 'AttendanceEditRequest',
    component: <HistoryItemComponent />,
    changing_fields: attendanceEditRequestFields,
    formated_fields: attendanceEditRequestFieldFormat
  },
  'App\\TenantModels\\Holiday': {
    title: `${LangHolidayCalendar}`,
    color: '#E64A47',
    value: 'Holiday',
    component: <HistoryItemComponent />,
    changing_fields: holidayFields,
    formated_fields: holidayFieldFormat
  },
  'App\\TenantModels\\Broadcast': {
    title: 'Broadcast',
    color: '#A2B38B',
    value: 'Broadcast',
    component: <HistoryItemComponent />,
    changing_fields: broadcastFields,
    formated_fields: broadcastFieldFormat
  },
  'App\\TenantModels\\UnlockLimitAttendanceRequest': {
    title: LangAttendanceApproval,
    color: '#002B5B',
    value: 'UnlockLimitAttendanceRequest',
    component: <HistoryItemComponent />,
    changing_fields: approvalFields,
    formated_fields: approvalFieldFormat
  },
  'App\\TenantModels\\Reimbursement': {
    title: LangClaimType,
    color: '#7d107b',
    value: 'Reimbursement',
    component: <HistoryItemComponent />,
    changing_fields: reimbursementFields,
    formated_fields: reimbursementFieldFormat
  },
  'App\\TenantModels\\ReimbursementPersonnel': {
    title: LangPersonnelClaim,
    color: '#7d107b',
    value: 'ReimbursementPersonnel',
    component: <HistoryItemComponent />,
    changing_fields: reimbursementPersonnelFields,
    formated_fields: reimbursementPersonnelFieldFormat
  },
  'App\\TenantModels\\ReimbursementClaim': {
    title: LangClaimRequest,
    color: '#7d107b',
    value: 'ReimbursementClaim',
    component: <HistoryItemComponent />,
    changing_fields: reimbursementRequestFields,
    formated_fields: reimbursementRequestFieldFormat
  },
  'App\\TenantModels\\WhitelistDevice': {
    title: LangDeviceApproval,
    color: '#31087B',
    value: 'WhitelistDevice',
    component: <HistoryItemComponent />,
    changing_fields: deviceApprovalFields,
    formated_fields: deviceApprovalFieldFormat
  },
  'App\\TenantModels\\ClientVisit': {
    title: LangClientVisit,
    color: '#FFCB42',
    value: 'ClientVisit',
    component: <HistoryItemComponent />,
    changing_fields: clientVisitFields,
    formated_fields: clientVisitFieldFormat
  },
  'App\\TenantModels\\PayrollDeduction': {
    title: `${LangDeduction} ${LangPayroll}`,
    color: '#E94560',
    value: 'PayrollDeduction',
    component: <HistoryItemComponent />,
    changing_fields: payrollDeductionFields,
    formated_fields: payrollDeductionFieldFormat
  },
  'App\\TenantModels\\PayrollBonus': {
    title: `Bonus ${LangPayroll}`,
    color: '#E94560',
    value: 'PayrollBonus',
    component: <HistoryItemComponent />,
    changing_fields: payrollBonusFields,
    formated_fields: payrollBonusFieldFormat
  },
  'App\\TenantModels\\LeaveInHourRequest': {
    title: LangLeaveHourRequest,
    color: '#9C2C77',
    value: 'LeaveInHourRequest',
    component: <HistoryItemComponent />,
    changing_fields: leaveRequestHourFields,
    formated_fields: leaveRequestHourFieldFormat
  },
  'App\\TenantModels\\PersonnelIdentity': {
    title: `${LangEmployeeIdentityFile}`,
    color: '#02A676',
    value: 'FileIdentity',
    component: <HistoryItemComponent />,
    changing_fields: fileIdentityFields,
    formated_fields: fileIdentityFormat
  },
  'App\\TenantModels\\SpecialLeave': {
    title: `${LangSpecialLeave}`,
    color: '#f5b63d',
    value: 'SpecialLeave',
    component: <HistoryItemComponent />,
    changing_fields: specialLeaveFields,
    formated_fields: specialLeaveFieldFormat
  },
  'App\\TenantModels\\TempNote': {
    title: `${LangJob}`,
    color: '#4a261a',
    value: 'TempNote',
    component: <HistoryItemComponent />,
    changing_fields: timesheetActivityFields,
    formated_fields: timesheetActivityFieldFormat
  },
  'App\\TenantModels\\Project': {
    title: `${LangProject}`,
    color: '#b48968',
    value: 'Project',
    component: <HistoryItemComponent />,
    changing_fields: timesheetProjectFields,
    formated_fields: timesheetProjectFieldFormat
  },
  'App\\TenantModels\\OvertimeSchedule': {
    title: `${LangOvertimeSchedule}`,
    color: '#1C5D99',
    value: 'OvertimeSchedule',
    component: <HistoryItemComponent />,
    changing_fields: overtimeScheduleFields,
    formated_fields: overtimeScheduleFieldFormat
  },
  'App\\TenantModels\\ApprovalRule': {
    title: `${LangApprovalRules}`,
    color: '#616264',
    value: 'ApprovalRule',
    component: <HistoryItemComponent />,
    changing_fields: approvalRuleFields,
    formated_fields: approvalRuleFieldFormat
  },
  'App\\TenantModels\\PersonnelProfilePictureRequest': {
    title: `${LangProfilePhotoApproval}`,
    color: '#017050',
    value: 'PersonnelProfilePictureRequest',
    component: <HistoryItemComponent />,
    changing_fields: personnelProfilePictureRequestFields,
    formated_fields: personnelProfilePictureRequestFieldFormat
  },
  'App\\TenantModels\\GateValidateError': {
    title: `${LangPintuKerjooAttendanceApproval2}`,
    color: '#017020',
    value: 'GateValidateError',
    component: <HistoryItemComponent />,
    changing_fields: pintuKerjooApprovalRequestFields,
    formated_fields: pintuKerjooApprovalRequestFieldsFormat
  },
  'App\\TenantModels\\Position': {
    title: `${LangPosition}`,
    color: '#300063',
    value: 'Position',
    component: <HistoryItemComponent />,
    changing_fields: positionFields,
    formated_fields: positionFieldFormat
  },
  'App\\TenantModels\\ClientVisitSchedule': {
    title: `${LangClientVisitSchedule}`,
    color: '#FE7041',
    value: 'ClientVisitSchedule',
    component: <HistoryItemComponent />,
    changing_fields: clientVisitScheduleFields,
    formated_fields: clientVisitScheduleFieldFormat
  },
  // 'App\\TenantModels\\AttendanceEdit': {
  //   title: `${Edit} ${DailyAttendance}`,
  //   color: '#8F79DB',
  //   value: 'AttendanceEdit',
  //   component: <AttendanceDailyHistoryItemComponent />
  // },
  // 'App\\User': {
  //   title: 'User',
  //   color: '#FF798C',
  //   component: <AdminHistoryItemComponent />
  // },
}

export const activityAction = {
  deleted: { label: LangDeleted, color: 'danger', old_val: 'delete', val: 'deleted' },
  updated: { label: LangEdited, color: 'primary', old_val: 'modify', val: 'updated' },
  created: { label: LangCreated, color: 'success', old_val: 'add', val: 'created' },
  modify_date_time: { label: LangEdited, color: 'primary', old_val: 'modify_date_time', val: 'modify_date_time' },
  modify_schedule: { label: LangEdited, color: 'primary', old_val: 'modify_schedule', val: 'modify_schedule' },
  delete_schedule: { label: LangDeleted, color: 'danger', old_val: 'delete-attendance', val: 'delete_schedule' }
}

export const excludedActivityLogsKey = ['App\\TenantModels\\AttendanceEdit']

export const excludedActionKey = ['modify_date_time', 'modify_schedule', 'delete_schedule']

export const isModifiedKeyList = ['updated', 'modify_date_time', 'modify_schedule']

export const toActivityLogs = (type, id) => (`/admin/activity-logs?subject_type=${type}${id ? `&subject_id=${id}` : ''}`)

// export const activityItem = {
//   'App\\TenantModels\\PersonnelLog': {
//     title: `${LangActivity} ${LangEmployee}`,
//     color: '#02A676',
//     component: <PersonnelHistoryItem />
//   },
//   'App\\TenantModels\\ShittWorkTimeLog': {
//     title: `${LangActivity} ${ShiftWorkTime}`,
//     color: '#FF5A33',
//     component: <WorkShiftHistoryItem />
//   },
//   'App\\TenantModels\\ShiftWorkPatternLog': {
//     title: `${LangActivity} ${WorkPattern} ${Shift}`,
//     color: '#0099DD',
//     component: <WorkPatternShiftHistoryItem />
//   },
//   'App\\TenantModels\\WorkPatternLog': {
//     title: `${LangActivity} ${WorkPattern} ${WorkDay}`,
//     color: '#00ABBD',
//     component: <WorkPatternDayHistoryItem />
//   },
//   'App\\TenantModels\\SpotLog': {

//     title: `${LangActivity} ${AttendanceSpot}`,
//     color: '#F2A71B',
//     component: <AttendanceSpotHistoryItem />
//   },
//   'App\\TenantModels\\WorkTimeLog': {
//     title: `${LangActivity} ${WorkPattern} ${WorkTime}`,
//     color: '#DBF227',
//     component: <WorkTimeHistoryItem />
//   },
//   'App\\TenantModels\\GroupLog': {
//     title: `${LangActivity} ${Group}`,
//     color: '#00635A',
//     component: <GroupHistoryItem />
//   },
//   'App\\TenantModels\\PermitLog': {
//     title: `${LangActivity} ${LangPermit}`,
//     color: '#F2A679',
//     addtionalProps: { type: 'permit' },
//     component: <PermitHistoryItem />
//   },
//   'App\\TenantModels\\LeaveRequestLog': {
//     title: `${LangActivity} ${LangLeave}`,
//     color: '#F2D091',
//     addtionalProps: { type: 'leaverequest' },
//     component: <PermitHistoryItem />
//   },
//   'App\\User': {
//     title: `${LangActivity} User`,
//     color: '#FF798C',
//     component: <AdminHistoryItemComponent />
//   },
//   'App\\TenantModels\\AttendanceEditLog': {
//     title: `${LangActivity} ${DailyAttendance}`,
//     color: '#8F79DB',
//     component: <AttendanceDailyHistoryItemComponent />
//   },
//   'App\\TenantModels\\Attendance': {
//     title: `${LangActivity} ${DailyAttendance}`,
//     color: '#8F79DB',
//     component: <AttendanceDailyHistoryItemComponent />
//   },
//   'App\\TenantModels\\AttendanceEditRequestLog': {
//     title: `${LangActivity} ${LangAttendanceRevision}`,
//     color: '#C13BE0',
//     component: <AttendanceRevisionHistoryItemComponent />
//   },
//   'App\\TenantModels\\HolidayLog': {
//     title: `${LangActivity} ${LangHolidayCalendar}`,
//     color: '#E64A47',
//     component: <CalendarHolidayHistoryItemComponent />
//   }
// }
