import React from 'react'
import { ModalApp } from '../../helpers/Input'
import {
  connect,
  mapStateToProps,
  SpinnerButton,
  SpinnerLoadFirst,
  PaginationDiv,
  moment,
  axiosCompany,
  httpBuildQuery,
  mapDispatchToProps,
  MODULES_NAMES,
  isBeforeNow,
  displayDate,
  getDivisionClientVisit,
  checkAuthorization
} from '../../helpers/Common'
import {
  EditSchedule,
  Name,
  Search,
  LangImportExcel,
  Division,
  LangVisitSchedule,
  LangClientVisit,
  LangImportExcelVisitSchedule,
  PrevWeek,
  NextWeek,
  LangExportExcel,
  LangClientDeletedAt,
  LangClientVisitMustOnSpot
} from '../../constant/languange'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faFileUpload, faPencilAlt, faSearch } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'
import PersonnelItemComponent from '../personnel/PersonnelItemComponent'
import VisitScheduleSelectClientComponent from './VisitScheduleSelectClientComponent'
import BreadcrumbsMenu from '../../layout/breadcrumb/breadcrumbMenu'
import VisitScheduleImportComponent from './VisitScheduleImportComponent'
import VisitScheduleImportFormatComponent from './VisitScheduleImportFormatComponent'
import VisitScheduleExportComponent from './VisitScheduleExportComponent'

class VisitScheduleComponent extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      loadFirst: true,
      loadData: false,
      filterPersonnel: {
        page: 1,
        group_id: '',
        keyword: '',
        is_active_client_visit: 1
      },
      dataGroup: [],
      dataResponse: {
        data: [],
        meta: {
          total: 0
        }
      },
      filterSchedule: {
        date_from: '',
        date_to: ''
      },
      currentDate: moment(),
      currentMonth: moment().format('M'),
      currentYear: moment().format('YYYY'),
      daysWeek: [],
      showModal: '',
      focusPersonnelId: '',
      focusDate: '',
      newWorkPattern: null,
      filter: {
        page: 1,
        target: null,
        group_id: '',
        date_start: moment().startOf('month').format('YYYY-MM-DD'),
        date_end: moment().format('YYYY-MM-DD'),
        keyword: ''
      },
      showLoad: false,
      currentData: null
    }

    this.loadData = this.loadData.bind(this)
    this.loadDataWithSetDaysWeek = this.loadDataWithSetDaysWeek.bind(this)
    this.loadDataPersonnelSchedules = this.loadDataPersonnelSchedules.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleGroupChange = this.handleGroupChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleClickSearch = this.handleClickSearch.bind(this)
    this.handleMonthChange = this.handleMonthChange.bind(this)
    this.handleYearChange = this.handleYearChange.bind(this)
    this.handleNextWeek = this.handleNextWeek.bind(this)
    this.handlePrevWeek = this.handlePrevWeek.bind(this)
    this.handleSuccessUpdateItem = this.handleSuccessUpdateItem.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  componentDidMount () {
    /**
     * load groups
     */
    if (this.props.redux?.groups) {
      // this.setState({
      //   dataGroup: this.props.redux.groups
      // })
      getDivisionClientVisit().then(data => this.setState({ dataGroup: data }))
    }
    const promises = []

    promises.push(new Promise((resolve) => {
      this.loadDataWithSetDaysWeek(() => {
        resolve()
      })
    }))

    promises.push(new Promise((resolve, reject) => {
      axiosCompany()
        .get('shiftworkpatterns?page=null')
        .then((result) => {
          resolve(result.data)
        })
        .catch(() => {
          reject()
        })
    }))

    Promise.all(promises).then((result) => {
      this.setState({
        loadFirst: false
      })

      this.props.setStateModule(MODULES_NAMES.SHIFT_WORK_PATTERN, { patterns: result[1] })
    })
    .catch(() => {
      this.setState({
        loadFirst: false
      })
    })
  }

  loadDataWithSetDaysWeek (cb) {
    const currentDate = this.state.currentDate
    const weekStart = currentDate.clone().startOf('isoWeek')
    // const weekEnd = currentDate.clone().endOf('week')

    const days = []
    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days'))
    }

    this.setState({
      currentDate,
      currentMonth: currentDate.format('M'),
      currentYear: currentDate.format('YYYY'),
      daysWeek: days,
      filterSchedule: Object.assign({}, {
        date_from: days[0].format('YYYY-MM-DD'),
        date_to: days[6].format('YYYY-MM-DD')
      })
    }, () => {
      this.loadData(cb)
    })
  }

  handleDateChange (value) {
    const filterSchedule = {
      date_from: value.startDate,
      date_to: value.endDate
    }

    this.setState(
      { filterSchedule: filterSchedule },
      () => {
        this.loadData()
      })
  }

  handleSuccessUpdateItem (personnelId, date) {
    this.setState({ showModal: '', newWorkPattern: null })
    this.loadDataPersonnelSchedules(personnelId)

    /**
     * cek perlu reload personnel gak?
     */
    const personnel = this.state.dataResponse.data.find((item) => +item.id === +personnelId)
    if (personnel.attr?.is_repeat_shift_schedule) {
      /**
       * cek tgl ada dalam daftar tidak
       */
      if (personnel.attr.repeat_shift_schedule.includes(date)) {
        this.refreshPersonnel(personnelId)
      }
    }
  }

  handlePrevWeek (e) {
    e.preventDefault()
    this.setState({
      currentDate: this.state.currentDate.subtract(1, 'week')
    }, () => {
      this.loadDataWithSetDaysWeek()
    })
  }

  handleNextWeek (e) {
    e.preventDefault()
    this.setState({
      currentDate: this.state.currentDate.add(1, 'week')
    }, () => {
      this.loadDataWithSetDaysWeek()
    })
  }

  handleYearChange (value) {
    this.setState({
      currentYear: value,
      currentDate: this.state.currentDate.set('year', value)
    }, () => {
      this.loadDataWithSetDaysWeek()
    })
  }

  handleMonthChange (value) {
    const currentDate = this.state.currentDate.set('month', +value - 1)

    this.setState({
      currentMonth: currentDate.format('M'),
      currentDate: currentDate
    }, () => {
      this.loadDataWithSetDaysWeek()
    })
  }

  handleClickSearch (e) {
    e.preventDefault()
    if (this.state.loadData) return true

    this.setState({
      filterPersonnel: { ...this.state.filterPersonnel, page: 1 }
    }, () => {
      this.loadData()
    })
  }

  handleKeyPress (e) {
    if (e.key === 'Enter') {
      if (this.state.loadData) return true

      this.setState({
        filterPersonnel: { ...this.state.filterPersonnel, page: 1 }
      }, () => {
        this.loadData()
      })
    }
  }

  handleGroupChange (groupId) {
    const filter = Object.assign({}, this.state.filterPersonnel)
    filter.page = 1
    filter.group_id = groupId

    this.setState({
      filterPersonnel: filter
    }, () => {
      this.loadData()
    })
  }

  handlePageChange (page) {
    const filter = Object.assign({}, this.state.filterPersonnel)
    filter.page = page

    this.setState({
      filterPersonnel: filter
    }, () => {
      this.loadData()
    })
  }

  refreshPersonnel (personnelId, cb) {
    axiosCompany()
      .get(`personnels/${personnelId}`)
      .then((result) => {
        const personnels = this.state.dataResponse.data.map((item) => {
          if (+item.id === +personnelId) {
            return result.data
          } else {
            return item
          }
        })

        const dataResponse = this.state.dataResponse
        dataResponse.data = personnels

        this.setState({ dataResponse }, () => {
          if (typeof cb === 'function') cb()
        })
      })
  }

  loadData (cb) {
    this.setState({ loadData: true })

    const filter = Object.assign({}, this.state.filterPersonnel)
    // console.log(filter)
    axiosCompany()
      .get('personnels?' + httpBuildQuery(filter))
      .then((result) => {
        this.setState({
          dataResponse: result.data,
          loadData: false,
          addedRepeatSchedule: result.data.data.filter((item) => (item.attr?.is_repeat_shift_schedule)).map(item => item.id)
        }, () => {
          /**
           * cari jadwal shift masing2 personnel
           */
          result.data.data.forEach((personnel) => {
            this.loadDataPersonnelSchedules(personnel.id)
          })

          if (typeof cb === 'function') cb()
        })
      })
      .catch(() => {
        this.setState({
          loadData: false
        })
      })
  }

  loadDataPersonnelSchedules (personnelId) {
    this.setState({
      [`dataSchedules${personnelId}`]: null
    })

    const filterSchedule = {
      date_from: this.state.filterSchedule.date_from,
      date_to: this.state.filterSchedule.date_to
    }

    if(checkAuthorization(this.props.redux, 'client-visit.schedule.index')) {
      axiosCompany()
        .get(`client-visit-schedules/${personnelId}?` + httpBuildQuery(filterSchedule))
        .then((resultSchedule) => {
          this.setState({
            [`dataSchedules${personnelId}`]: resultSchedule.data
          })
        })
    }
  }

  render () {
    const s = this.state
    const p = this.props

    const isNow = (date) => {
      return date.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    }

    const ScheduleList = (props) => {
      const date = moment(props.date).format('DD')
      const isDayBefore = isBeforeNow(props.date.format('YYYY-MM-DD'))

      const rowSchedule = s[`dataSchedules${props.personnelId}`]
      if (!rowSchedule) return <center>{SpinnerButton}</center>

      const colSchedule = rowSchedule.filter((item) => item.date === props.date.format('YYYY-MM-DD'))
      if (!colSchedule) return <center>{SpinnerButton}</center>

      return (
        <div>
          <div className='d-flex justify-content-center'>
            <div>
              <ul className='list-group'>
                {colSchedule.map(item => (
                  <li key={item.id} className='list-group-item py-1 px-3'>
                    <span className='text-nowrap d-flex align-items-center'>
                      {item.client.name} {item.start_time && `(${item.start_time}`}{item.end_time && ` - ${item.end_time}`}{item.start_time && `)`} 
                      {item.client_visit_must_on_spot ? <svg className='ml-1 text-success ft-14' style={{ fontSize: 20 }} data-for={`tooltip-${props.personnelId}-${date}`} data-tip={LangClientVisitMustOnSpot} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2c3.31 0 6 2.66 6 5.95C18 12.41 12 19 12 19S6 12.41 6 7.95C6 4.66 8.69 2 12 2m0 4a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2m8 13c0 2.21-3.58 4-8 4s-8-1.79-8-4c0-1.29 1.22-2.44 3.11-3.17l.64.91C6.67 17.19 6 17.81 6 18.5c0 1.38 2.69 2.5 6 2.5s6-1.12 6-2.5c0-.69-.67-1.31-1.75-1.76l.64-.91C18.78 16.56 20 17.71 20 19"/></svg> : ''}
                    </span>
                    {item.client.deleted_at && (
                      <span className='badge badge-danger'>
                        {LangClientDeletedAt}: {displayDate(item.client.deleted_at, 'DD MMM YYYY @ HH:mm')}
                      </span>
                    )}
                  </li>
                ))}
              </ul>
              {!isDayBefore && checkAuthorization(p.redux, 'client-visit.schedule.update') && (
                <div className='d-flex justify-content-center mt-2'>
                  <button data-for={`tooltip-${props.personnelId}-${date}`} data-tip={EditSchedule} onClick={() => this.setState({ showModal: 'edit', focusPersonnelId: props.personnelId, focusDate: props.date, currentData: colSchedule })} type='button' className='btn btn-sm btn-light'><FontAwesomeIcon icon={faPencilAlt} /></button>
                </div>
              )}
            </div>
          </div>
          <ReactTooltip id={`tooltip-${props.personnelId}-${date}`} place='bottom' />
        </div>
      )
    }

    const PersonnelList = s.dataResponse?.data?.map((item, i) => {
      const oddColor = p.redux.darkModeOn ? '#2c323f' : 'white'
      const evenColor = p.redux.darkModeOn ? '#373c49' : 'rgba(245, 247, 250, 1)'

      return (
        <tr key={item.id}>
          <td className='table-column-sticky-left' style={{ zIndex: 2, backgroundColor: (i % 2 !== 0 ? oddColor : evenColor) }}>
            <PersonnelItemComponent key={'personnel-' + item.id} data={item} />
          </td>
          <td style={{ minWidth: 200 }}>
            <ScheduleList personnel={item} personnelId={item.id} date={s.daysWeek[0]} />
          </td>
          <td style={{ minWidth: 200 }}>
            <ScheduleList personnel={item} personnelId={item.id} date={s.daysWeek[1]} />
          </td>
          <td style={{ minWidth: 200 }}>
            <ScheduleList personnel={item} personnelId={item.id} date={s.daysWeek[2]} />
          </td>
          <td style={{ minWidth: 200 }}>
            <ScheduleList personnel={item} personnelId={item.id} date={s.daysWeek[3]} />
          </td>
          <td style={{ minWidth: 200 }}>
            <ScheduleList personnel={item} personnelId={item.id} date={s.daysWeek[4]} />
          </td>
          <td style={{ minWidth: 200 }}>
            <ScheduleList personnel={item} personnelId={item.id} date={s.daysWeek[5]} />
          </td>
          <td style={{ minWidth: 200 }}>
            <ScheduleList personnel={item} personnelId={item.id} date={s.daysWeek[6]} />
          </td>
        </tr>
      )
    })

    return (
      <div>
        <BreadcrumbsMenu title={LangVisitSchedule}>{[LangClientVisit, LangVisitSchedule]}</BreadcrumbsMenu>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col md-12'>
              <div className='card'>
                <div className='card-header'>
                  {/* top menu */}
                  <div className='d-md-flex flex-wrap justify-content-between mb-4'>

                    <div className='d-md-flex'>
                      <div className='mr-2 mb-2 px-0' style={{ minWidth: 200 }}>
                        <select disabled={s.loadData} value={s.filterPersonnel.group_id} className='form-control' style={{ maxWidth: 200 }} onChange={(e) => this.handleGroupChange(e.target.value)}>
                          <option value=''>--{Division}--</option>
                          {s.dataGroup.map((obj) => {
                            return (
                              <option key={obj.id} value={obj.id}>{obj.name}</option>
                            )
                          })}
                        </select>
                      </div>

                      <div className='mr-2 mb-2' style={{ minWidth: 180 }}>
                        <div className='input-group'>
                          <input value={s.filterPersonnel.keyword} onChange={(e) => this.setState({ filterPersonnel: { ...s.filterPersonnel, keyword: e.target.value } })} disabled={s.loadData} type='search' className='form-control' placeholder={Search} onKeyPress={this.handleKeyPress} style={{ maxWidth: 200 }} />
                          <div className='input-group-append' style={{ cursor: 'pointer' }} onClick={this.handleClickSearch}><span className={`input-group-text ${p.redux.darkModeOn ? 'bg-dark border-dark' : 'bg-light text-dark'}`}><i> {s.loadData ? SpinnerButton : <FontAwesomeIcon icon={faSearch} />} </i></span></div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex flex-wrap'>
                      {checkAuthorization(p.redux, 'client-visit.schedule.import') && (
                        <button onClick={() => this.setState({ showModal: 'import' })} disabled={s.loadData} type='button' className='mb-2 mr-2 btn btn-primary text-nowrap'>{(s.loadData) ? SpinnerButton : <FontAwesomeIcon icon={faFileDownload} />} {LangImportExcel}</button>
                      )}
                      {checkAuthorization(p.redux, 'client-visit.schedule.export') && (
                        <button onClick={() => this.setState({ showModal: 'export' })} disabled={s.loadData} type='button' className='mb-2 btn btn-primary text-nowrap'>{(s.loadData) ? SpinnerButton : <FontAwesomeIcon icon={faFileUpload} />} {LangExportExcel}</button>
                      )}
                    </div>
                  </div>

                  <div className='col-md-12 mt-3 mb-3'>
                    <div className='d-flex flex-column flex-md-row flex-lg-column flex-xl-row justify-content-lg-center'>
                      <div className='mr-2 mb-2 mb-md-0 mb-lg-2 mb-xl-0'>
                        <div className='btn-group d-flex flex-column flex-sm-row h-100'>
                          <button onClick={this.handlePrevWeek} disabled={s.loadData} className='btn btn-success' type='button'>&larr; {PrevWeek}</button>
                          <button onClick={this.handleNextWeek} disabled={s.loadData} className='btn btn-success' type='button'>{NextWeek} &rarr;</button>
                        </div>
                      </div>
                      <div>
                        <div className='d-flex flex-row'>
                          <div className='mr-2'>
                            <select disabled={s.loadData} onChange={(e) => this.handleMonthChange(e.target.value)} value={s.currentMonth} className='form-control'>
                              {moment.months().map((item, index) => (
                                <option value={index + 1} key={index}>{item}</option>
                              ))}
                            </select>
                          </div>
                          <div className='mr-2'>
                            <select disabled={s.loadData} onChange={(e) => this.handleYearChange(e.target.value)} value={s.currentYear} className='form-control'>
                              <option value={moment().subtract(1, 'years').format('YYYY')}>{moment().subtract(1, 'years').format('YYYY')}</option>
                              <option value={moment().format('YYYY')}>{moment().format('YYYY')}</option>
                              <option value={moment().add(1, 'years').format('YYYY')}>{moment().add(1, 'years').format('YYYY')}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {checkAuthorization(this.props.redux, 'client-visit.schedule.index') ? (
                  <>
                    {s.loadFirst
                      ? SpinnerLoadFirst
                      : (
                        <div className='table-responsive' style={{ maxHeight: '60vh' }}>
                          <table className='table table-striped table-bordered table-border-separate'>
                            <thead>
                              <tr>
                                <th scope='col' className={`text-center align-middle table-column-sticky-left th-sticky-corner ${p.redux.darkModeOn ? 'table-dark bg-dark' : ''}`} style={{ zIndex: 3, minWidth: '330px' }}>{Name}</th>
                                {
                                    Array(7).fill('').map((item, i) => (
                                      <th key={i} className={`text-center align-middle table-th-sticky-base ${isNow(s.daysWeek[i]) ? (p.redux.darkModeOn ? 'table-success text-dark' : 'table-success') : (p.redux.darkModeOn ? 'table-dark bg-dark' : 'table-light')}`} style={{ zIndex: 2 }}>{s.daysWeek[i].format('dddd, DD MMM YYYY')}</th>
                                    ))
                                  }
                              </tr>
                            </thead>
                            <tbody>
                              {(s.loadData)
                                ? (
                                  <tr>
                                    <td colSpan='8'>{SpinnerLoadFirst}</td>
                                  </tr>
                                  )
                                : PersonnelList}
                            </tbody>
                          </table>
                        </div>
                        )}
                  </>
                ) : (
                  <div class='alert alert-danger my-2 w-100'>This action is unauthorized.</div>
                )}
                <div className='card-footer'>
                  <PaginationDiv data={s.dataResponse} onChange={this.handlePageChange} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {(s.showModal === 'export')
          ? (
            <ModalApp
              title={LangExportExcel}
              handleClose={() => this.setState({ showModal: '' })}
              body={
                <VisitScheduleExportComponent
                  totalTarget={s.dataResponse.meta.total}
                  groupId={s.filterPersonnel.group_id}
                  currentMonth={s.currentMonth}
                  currentYear={s.currentYear}
                  onChangeGroup={(groupId) => this.handleGroupChange(groupId)}
                  onChangeMonth={(value) => this.handleMonthChange(value)}
                  onChangeYear={(value) => this.handleYearChange(value)}
                  onUpdate={() => {
                    this.loadData()
                  }}
                  onClose={() => this.setState({ showModal: '' })}
                />
              }
            />
            )
          : null}

        {(s.showModal === 'import') && (
          <ModalApp
            title={LangImportExcel}
            handleClose={() => this.setState({ showModal: '' })}
            body={
              <VisitScheduleImportComponent
                handleClose={() => this.setState({ showModal: '' })}
                handleDownloadFormat={() => this.setState({ showModal: 'import-format' })}
                handleUpdate={() => this.loadData()}
              />
              }
          />
        )}

        {(s.showModal === 'import-format') && (
          <ModalApp
            title={LangImportExcelVisitSchedule}
            handleClose={() => this.setState({ showModal: 'import' })}
            body={
              <VisitScheduleImportFormatComponent
                handleClose={() => this.setState({ showModal: 'import' })}
                groups={s.dataGroup}
              />
              }
          />
        )}

        {(s.showModal === 'edit') && (
          <ModalApp
            title={EditSchedule}
            size='lg'
            handleClose={() => this.setState({ showModal: '' })}
            body={
              <VisitScheduleSelectClientComponent
                onUpdate={() => this.loadData()}
                handleClose={() => this.setState({ showModal: '' })}
                currentData={s.currentData}
                focusPersonnelId={s.focusPersonnelId}
                focusDate={s.focusDate}
              />
              }
          />
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitScheduleComponent)
