import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Home } from '../../constant/languange'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { MENUITEMS, NEWMENUITEMS } from '../sidebar/menu'
import { ModalApp } from '../../helpers/Input'
import { connect } from 'react-redux'
import { cookies, mapStateToProps } from '../../helpers/Common'

export const ModalSelectMenu = ({ menus, redux }) => {
  const loc = useLocation()
  return (
    <>
      <div className='list-group'>
        {menus.map((menu, i) => (
          <Link key={i} to={menu[0]} className={`list-group-item ${loc.pathname === menu[0] ? redux.darkModeOn ? 'active bg-primary' : 'active' : ''} ${redux.darkModeOn ? 'bg-dark text-white' : ''}`}>{menu[1]}</Link>
        ))}
      </div>
    </>
  )
}

const BreadcrumbsMenu = (props) => {
  const [menus, setMenus] = useState([])
  const [showModal, setShowModal] = useState()
  const loc = useLocation()
  const history = useHistory()

  useEffect(() => {
    setMenus([])
    getDataMenus()
    // eslint-disable-next-line
  }, [])

  const getDataMenus = () => {
    const dataMenu = cookies.get('menu-version') === '2' ? NEWMENUITEMS : MENUITEMS
    dataMenu?.map(menus => {
      menus?.Items[0]?.children?.map(item => {
        if (item.path === loc.pathname) {
          menus.Items[0].children.map(menu => setMenus(state => [...state, [menu.path, menu.title]]))
        } else {
          if (item.hasOwnProperty('childs')) {
            if (loc.pathname.replace(/[0-9]/g, ':id').includes(item.childs[0])) {
              menus.Items[0].children.map(menu => setMenus(state => [...state, [menu.path, menu.title]]))
            }
          }
        }
      })
    })
  }

  const handleClickMenu = () => {
    if (props.children.length < 2) {
      return (
        <>
          {props.children.map((menu, i) => {
            return <BreadcrumbItem key={i} active>{menu}</BreadcrumbItem>
          })}
        </>
      )
    }

    return (
      <>
        {props.children.map((menu, i) => {
          if (menu === props.children[0]) {
            if(menus.length === 0) {
              return <BreadcrumbItem key={i} active><a onClick={history.goBack} style={{ cursor: 'pointer' }}>{menu}</a></BreadcrumbItem>
            }
            return <BreadcrumbItem key={i} active><a onClick={() => setShowModal('settings')} style={{ cursor: 'pointer' }}>{menu}</a></BreadcrumbItem>
          } else {
            if (i !== props.children.length - 1) {
              return <BreadcrumbItem key={i} active><a onClick={history.goBack} style={{ cursor: 'pointer' }}>{menu}</a></BreadcrumbItem>
            }
            return <BreadcrumbItem key={i} active>{menu}</BreadcrumbItem>
          }
        })}
      </>
    )
  }

  return (
    <>
      <Container fluid>
        <div className='page-title'>
          <Row>
            <Col lg='7'>
              <h3>{props.title}</h3>
              <Breadcrumb className='breadcrumb justify-content-start mt-3'>
                <BreadcrumbItem><Link to='/dashboard'>{Home}</Link></BreadcrumbItem>
                {handleClickMenu()}
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>

      {showModal === 'settings' &&
        <ModalApp
          title={props.children[0]}
          handleClose={() => setShowModal({ showModalName: '' })}
          size='sm'
          body={<ModalSelectMenu menus={menus} redux={props.redux} />}
        />}
    </>
  )
}

export default connect(mapStateToProps)(BreadcrumbsMenu)
