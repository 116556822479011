import React from 'react'
import {
  cookies,
  axios,
  apiUrl,
  SpinnerLoadPages,
  Redirect,
  connect,
  mapStateToProps,
  mapDispatchToProps,
  setAnnualLeaveLabel
} from '../helpers/Common'

/**
 * cek sudah login belum
 */
class AuthCheckComponent extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      loadFirst: true
    }
  }

  componentDidMount () {
    /**
     * cek dulu sudah auth belum
     */
    if (this.props.redux.authed) {
      this.setState({ loadFirst: false })
      return true
    }

    /**
     * cek dulu ada cookie tidak
     */
    const token = cookies.get('token')

    /**
     * jika tidak ada berarti memang belum login
     */
    if (!token) {
      this.setState({ loadFirst: false })
    } else {
      axios.get(apiUrl + '/auth/myprofile', {
        headers: { Authorization: `Bearer ${token}` }
      }).then((result) => {
        /**
         * jika ada isinya berarti personnel
         */
        if (result.data.personnel_id && (!result.data.supervisor_groups || result.data.supervisor_groups.length <= 0)) {
          cookies.remove('token')
          this.setState({ loadFirst: false })
        } else {
          /**
           * ambil company & data init
           */
          axios.get(result.data.company_api_url + '/company?with_init_data=1', {
            headers: { Authorization: `Bearer ${token}` }
          }).then((result2) => {
            /**
             * update redux data
             */
            if (result.data.attr.timezone === 'wita') {
              result.data.timeZone = 'Asia/Ujung_Pandang'
            } else if (result.data.attr.timezone === 'wit') {
              result.data.timeZone = 'Asia/Jayapura'
            } else {
              result.data.timeZone = 'Asia/Jakarta'
            }

            this.props.setState({
              authed: true,
              profile: result.data,
              company: result2.data.profile,
              addons: result2.data.addons,
              groups: result2.data.groups,
              personnelsCount: result2.data.personnels_count,
              isTrial: result2.data.is_trial,
              isTemporary: result2.data.is_temporary,
              featureLimitation: result2.data.feature_limitation,
              features: result2.data.features,
              createdAt: result2.data.created_at,
              expiredAt: result2.data.expired_at,
              enableFeatureCustomerDivision: result2.data.enable_feature_customer_division,
              packageName: result2.data.package_name,
              pricePerPersonnel: result2.data.price_per_personnel,
              personnelsQuota: result2.data.personnels_quota,
              broadcastQuota: result2.data.broadcasts_quota,
              calculateDistanceQuota: result2.data.calculate_distance_quota,
              spotQuota: result2.data.spot_quota || 3,
              additionalSpotQuota: result2.data.additional_spot_quota,
              apiDocUrl: result2.data.api_docs_url,
              enableFeatureLimitAttendance: result2.data.enable_feature_limit_attendance,
              enableWhitelistDevice: result2.data.enable_feature_whitelist_device,
              enableAutoClockout: result2.data.enable_feature_auto_clock_out,
              enableFeaturePayroll: result2.data.enable_feature_payroll,
              enableFeatureClientVisit: result2.data.enable_feature_client_visit,
              enableFeatureDocumentPersonnel: result2.data.enable_feature_document_personnel,
              enableFeatureSpecialLeave: result2.data.enable_feature_special_leaves,
              enableFeatureReimbursement: result2.data.enable_feature_reimbursements,
              enableFeaturePintuKerjoo: result2.data.enable_feature_pintu_kerjoo,
              enableFeatureMultilevelApproval: result2.data.enable_feature_multilevel_approval,
              enableFeatureTimesheet: result2.data.enable_feature_timesheet,
              enableFeatureSubAdmin: result2.data.enable_feature_sub_admin
            })

            // if (!cookies.get('annual_label_id') && !cookies.get('annual_label_en')) {
            //  setAnnualLeaveLabel(result2.data.profile.leave_request_button_label, 'id')
            //  setAnnualLeaveLabel(result2.data.profile.leave_request_button_label_en, 'en')
            //  window.location.reload()
            // }

            const userLang = result.data.attr.lang ? result.data.attr.lang.toUpperCase() : 'ID'
            if (!cookies.get('lang')) {
              cookies.set('lang', userLang) // set language cookies
              window.location.reload()
            } else {
              /**
               * kalo sudah ada coba dibandingkan, sudah sama belum
               */
              if (cookies.get('lang') !== userLang) {
                cookies.set('lang', userLang)
                window.location.reload()
              }
            }

            this.setState({ loadFirst: false })
          }).catch(() => {
            cookies.remove('token')
            this.setState({ loadFirst: false })
          })
        }
      }).catch(() => {
        /**
         * hapus saja cookienya untuk login ulang
         */
        cookies.remove('token')
        this.setState({ loadFirst: false })
      })
    }
  }

  render () {
    const { loadFirst } = this.state
    const { redux, children } = this.props

    /**
     * render spiner
     */
    if (loadFirst) return SpinnerLoadPages

    /**
     * cek data redux apakah sudah login?
     */
    if (!redux.authed) {
      return <Redirect to='/login' />
    }

    /**
     * render component dibawahnya
     */
    return children
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthCheckComponent)
