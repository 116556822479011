import React, { useState, useEffect } from 'react'
import {
  checkAuthorization,
  connect,
  mapStateToProps
} from '../../helpers/Common'

export default connect(mapStateToProps)(({ redux }) => {
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (Object.keys(redux.notifications).length) {
      let filteredCount = 0
      if (checkAuthorization(redux, 'attendance-approval.index')) {
        filteredCount += (redux.notifications.unlock_limit_attenance_request ?? 0)
      }
      if (checkAuthorization(redux, 'attendance-revision.index')) {
        filteredCount += (redux.notifications.attendance_edit_request ?? 0)
      }
      if (checkAuthorization(redux, 'device-approval.index')) {
        filteredCount +=  (redux.notifications.whitelist_device ?? 0)
      }
      if (checkAuthorization(redux, 'pintu-kerjoo.approval')) {
        filteredCount +=  (redux.notifications.pintu_kerjoo_sync_error ?? 0)
      }
      if (checkAuthorization(redux, 'picture-approval.index')) {
        filteredCount += (redux.notifications.update_profile_picture ?? 0)
      }
      setCount(filteredCount)
    }
    // eslint-disable-next-line
  }, [redux.notifications.unlock_limit_attenance_request, redux.notifications.attendance_edit_request, redux.notifications.whitelist_device, redux.notifications.pintu_kerjoo_sync_error, redux.notifications.update_profile_picture])

  if (count <= 0) return <></>

  return (
    <label className='badge badge-warning position-static m-0 py-1' style={{ fontSize: '9px' }}>{count}</label>
  )
})
