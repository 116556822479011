import React from 'react'
import {
  Home,
  Settings,
  Unlock,
  Radio,
  Clock,
  User,
  File,
  Users,
  Info,
  PauseCircle,
  Tablet,
  Code,
  Rss,
  Briefcase,
  DollarSign,
  Star,
  MapPin,
  Smartphone,
  Trash2
} from 'react-feather'
import {
  Dashboard, CompanySetting, ManageAdmin, AttendanceSpot, WorkPattern, ManageEmployees, DataEmployees,
  ShiftWorkTime, WorkTime, ShiftDay, WorkDay, DailyAttendance, AttendenceSummary, ManageDivision,
  AttendanceReview,
  LangMenuLeaveRequest,
  LangSetting,
  LangMenuSubscribeInfo,
  LangDeveloper,
  MobileAplication,
  LangHolidayCalendar,
  LangAttendanceRevision,
  LangOvertimeReview,
  LangPermitHourReview,
  LangAttendanceApproval,
  LangDeviceApproval,
  LangClientVisit,
  LangCalculate,
  LangPaid,
  LangPayroll,
  LangPremiumFeatures,
  LangAttendance,
  LangOvertimeSalary,
  LangVisit,
  LangClient,
  LangVisitSchedule,
  LangShiftChangeScheduleRecap,
  LangVisitSummary,
  LangAccPintuKerjoo,
  LangOvertimeCalculation,
  LangUsageGuide,
  LangManagePosition,
  LangSpecialLeave,
  LangUnpaidLeave,
  LangLeaveDay,
  LangClaim,
  LangClaimType,
  LangClaimRequest,
  LangReview,
  LangSubscription,
  LangUserAdmin,
  LangOvertime,
  LangPermitHours,
  LangPintuKerjooAttendance,
  Employe,
  LangApprovalRules,
  LangTimesheet,
  LangActivity,
  LangProject,
  LangResetData,
  LangOvertimeSchedule,
  LangClientVisitVisualization,
  LangProfilePhotoApproval,
  LangProfileApproval,
  LangBPJS,
  LangUserSubAdmin,
  LangAttendanceSyncData,
  LangAttendanceSyncDataShort,
  LangSalaryPaidStatistics,
  LangAdminNotification
} from '../../constant/languange'
import BadgeAttendanceApproval from './BadgeAttendanceApproval'
import BadgeAttendanceParentMenu from './BadgeAttendanceParentMenu'
import BadgeAttendanceReview from './BadgeAttendanceReview'
import BadgeAttendanceRevision from './BadgeAttendanceRevision'
import BadgeDeviceApproval from './BadgeDeviceApproval'
import BadgeLeaveRequest from './BadgeLeaveRequest'
import BadgePermitRequest from './BadgePermitRequest'
import Badges from './Badges'
import { faBookOpen, faBusinessTime, faClipboardCheck, faFilePdf, faHandHoldingUsd, faHandPaper, faRocket, faStreetView, faUserCheck, faUserCog, faUserFriends, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BadgeClaimRequest from './BadgeClaimRequest'
import BadgePintuKerjoo from './BadgePintuKerjoo'
import BadgeReview from './BadgeReview'
import BadgesParent from './BadgesParent'
import BadgeAttendancePhotoProfileApproval from './BadgeAttendancePhotoProfileApproval'
import BadgeAttendanceParentMenuV1 from './BadgeAttendanceParentMenuV1'
import moment from 'moment'
import { cookies } from '../../helpers/Common'

const activeLang = cookies.get('lang') ? cookies.get('lang').toLowerCase() : 'id'

export const NEWMENUITEMS = [
  {
    menucontent: 'Dashboard',
    category: 'analytics',
    Items: [
      {
        title: Dashboard, icon: Home, path: `${process.env.PUBLIC_URL}/dashboard`, type: 'link', active: !!(window.location.hash === '#/dashboard' || window.location.hash === ''), key: 'dashboard.index'
      }
    ]
  },
  {
    menucontent: 'Attendance',
    category: 'analytics',
    Items: [
      {
        title: LangAttendance,
        icon: <FontAwesomeIcon className='txt-dark' icon={faStreetView} size='lg' />,
        type: 'sub',
        active: ['#/daily/attendance', '#/attendance/summary', '#/shift-change-schedule-recap'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/daily/attendance`, title: DailyAttendance, type: 'link', active: window.location.hash === '#/daily/attendance', key: 'daily-attendance.index' },
          { path: `${process.env.PUBLIC_URL}/attendance/summary`, title: AttendenceSummary, type: 'link', active: window.location.hash === '#/attendance/summary', key: 'attendance-summary.index' },
          { path: `${process.env.PUBLIC_URL}/shift-change-schedule-recap`, title: LangShiftChangeScheduleRecap, type: 'link', active: window.location.hash === '#/shift-change-schedule-recap', key: 'switch-shift-recap.index' }
        ]
      }
    ]
  },
  {
    menucontent: 'Review',
    category: 'analytics',
    Items: [
      {
        title: LangReview,
        icon: <FontAwesomeIcon className='txt-dark' style={{ fontSize: 18, marginLeft: 1.7, marginBottom: 1.3 }} icon={faClipboardCheck} />,
        type: 'sub',
        active: ['#/attendance/review', '#/overtime-review', '#/shift-change-schedule-recap'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/attendance/review`, title: LangAttendance, type: 'link', active: window.location.hash === '#/attendance/review', badge: <BadgeAttendanceReview />, key: 'attendance-review.index' },
          { path: `${process.env.PUBLIC_URL}/overtime-review`, title: LangOvertime, type: 'link', active: window.location.hash === '#/overtime-review', childs: ['/overtime-review/rejection-history'], key: 'overtime.review.index' },
          { path: `${process.env.PUBLIC_URL}/permithour-review`, title: LangPermitHours, type: 'link', active: window.location.hash === '#/permithour-review', key: 'unpaid-leave.hour.index' }
        ],
        badge: <BadgeReview />
      }
    ]
  },
  {
    menucontent: 'Client Visit',
    category: 'analytics',
    Items: [
      {
        title: LangClientVisit,
        icon: MapPin,
        type: 'sub',
        active: ['#/client-visit', '#/visit-summary', '#/client-visit-visualization'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/client-visit`, title: LangVisit, type: 'link', active: window.location.hash === '#/client-visit', key: 'client-visit.index' },
          { path: `${process.env.PUBLIC_URL}/client-visit-visualization`, title: LangClientVisitVisualization, type: 'link', active: window.location.hash === '#/client-visit-visualization', key: 'client-visit.visualization.index' },
          { path: `${process.env.PUBLIC_URL}/visit-summary`, title: LangVisitSummary, type: 'link', active: window.location.hash === '#/visit-summary', key: 'client-visit.summary.index' }
        ]
      }
    ],
    badge: <Badges releaseDate='2022-12-12' />
  },
  {
    menucontent: 'Attendance',
    category: 'approval',
    Items: [
      {
        title: LangAttendance,
        icon: <FontAwesomeIcon className='txt-dark' style={{ marginLeft: 2 }}  icon={faUserCheck} />,
        type: 'sub',
        active: ['#/attendance-approval', '#/attendance/revision', '#/device-approval', '#/pintu-kerjoo/attendance-approval', '#/attendance/profile-picture-approval', '#/pintu-kerjoo/attendance-sync-data'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/attendance-approval`, title: LangAttendanceApproval, type: 'link', active: window.location.hash === '#/attendance-approval', badge: <BadgeAttendanceApproval />, key: 'attendance-approval.index' },
          { path: `${process.env.PUBLIC_URL}/attendance/revision`, title: LangAttendanceRevision, type: 'link', active: window.location.hash === '#/attendance/revision', badge: <BadgeAttendanceRevision />, key: 'attendance-revision.index' },
          { submenucontent: 'Device Approval', path: `${process.env.PUBLIC_URL}/device-approval`, title: LangDeviceApproval, type: 'link', active: window.location.hash === '#/device-approval', badge: <BadgeDeviceApproval />, key: 'device-approval.index' },
          { submenucontent: 'Profile Picture Approval', path: `${process.env.PUBLIC_URL}/attendance/profile-picture-approval`, title: moment().isBefore(moment('2024-10-30')) ? LangProfileApproval : LangProfilePhotoApproval, type: 'link', active: window.location.hash === '#/attendance/profile-picture-approval', badge: <><Badges releaseDate='2024-09-30' /><BadgeAttendancePhotoProfileApproval /></>, key: 'picture-approval.index' },
          { submenucontent: 'Pintu Kerjoo Attendance Approval', path: `${process.env.PUBLIC_URL}/pintu-kerjoo/attendance-approval`, title: LangPintuKerjooAttendance, type: 'link', active: window.location.hash === '#/pintu-kerjoo/attendance-approval', badge: <BadgePintuKerjoo />, key: 'pintu-kerjoo.approval' },
          { submenucontent: 'Pintu Kerjoo Data Sync', path: `${process.env.PUBLIC_URL}/pintu-kerjoo/attendance-sync-data`, title: LangAttendanceSyncDataShort, type: 'link', active: window.location.hash === '#/pintu-kerjoo/attendance-sync-data', key: 'pintu-kerjoo.sync.index' }
        ],
        badge: <><Badges releaseDate='2024-09-30' /><BadgeAttendanceParentMenu /></>
      }
    ]
  },
  {
    menucontent: 'Permit',
    category: 'approval',
    Items: [
      {
        title: LangUnpaidLeave,
        icon: <FontAwesomeIcon className='txt-dark' style={{ fontSize: 18 }} icon={faHandPaper} />,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/permit/request`,
        active: window.location.hash === '#/permit/request',
        badge: <><Badges releaseDate='2022-12-12' /> <BadgePermitRequest /></>,
        key: 'unpaid-leave.day.index'
      }
    ]
  },
  {
    menucontent: 'Leave Request',
    category: 'approval',
    Items: [
      {
        title: LangMenuLeaveRequest,
        icon: Briefcase,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/leave/request`,
        active: window.location.hash === '#/leave/request',
        badge: <BadgeLeaveRequest />,
        key: 'paid-leave.day.index'
      }
    ]
  },
  {
    menucontent: 'Claim',
    category: 'approval',
    Items: [
      {
        title: LangClaim,
        icon: <FontAwesomeIcon className='txt-dark' style={{ fontSize: 18 }}  icon={faHandHoldingUsd} />,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/claim/request`,
        active: window.location.hash === '#/claim/request',
        badge: <BadgeClaimRequest />,
        key: 'claim.request.index'
      }
    ]
  },
  {
    menucontent: 'Settings Employee',
    category: 'manageEmployee',
    Items: [
      {
        title: Employe,
        icon: <FontAwesomeIcon className='txt-dark' icon={faUsers} />,
        type: 'sub',
        active: ['#/manage/group', '#/manage/position', '#/personnels', '#/attendance/spot', '#/personnel/work/time', '#/personnel/work/shift', '#/overtime-schedule'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/manage/group`, title: ManageDivision, type: 'link', active: window.location.hash === '#/manage/group', childs: ['/manage/group', '/manage/group/:id/punishment'], key: 'group.index' },
          { path: `${process.env.PUBLIC_URL}/manage/position`, title: LangManagePosition, type: 'link', active: window.location.hash === '#/manage/position', childs: ['/manage/position', '/manage/position/visualization'], key: 'position.index'},
          { path: `${process.env.PUBLIC_URL}/personnels`, title: DataEmployees, type: 'link', active: window.location.hash === '#/personnels', childs: ['/personnels/create', '/personnels/deleted', '/personnels/:id/edit', '/personnels/:id/detail', '/personnels/:id/document'], key: 'employee.index' },
          { path: `${process.env.PUBLIC_URL}/attendance/spot`, title: AttendanceSpot, type: 'link', active: window.location.hash === '#/attendance/spot', key: 'spot.index' },
          { path: `${process.env.PUBLIC_URL}/personnel/work/time`, title: WorkTime, type: 'link', active: window.location.hash === '#/personnel/work/time', key: 'employee.workday-schedule.index' },
          { path: `${process.env.PUBLIC_URL}/personnel/work/shift`, title: ShiftWorkTime, type: 'link', active: window.location.hash === '#/personnel/work/shift', key: 'employee.shift-schedule.index' },
          { path: `${process.env.PUBLIC_URL}/overtime-schedule`, title: LangOvertimeSchedule, type: 'link', active: window.location.hash === '#/overtime-schedule', badge: <Badges releaseDate='2024-07-29' />, key: 'overtime.schedule.index' }
        ],
        badge: <BadgesParent releaseDate='2024-07-29' limitation={[]}/>
      }
    ]
  },
  {
    menucontent: 'Work Time',
    category: 'manageEmployee',
    Items: [
      {
        title: WorkPattern,
        icon: Clock,
        type: 'sub',
        active: ['#/work/day', '#/work/shift'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/work/day`, title: WorkDay, type: 'link', active: window.location.hash === '#/work/day', key: 'work-pattern.work-day.index' },
          { path: `${process.env.PUBLIC_URL}/work/shift`, title: ShiftDay, type: 'link', active: window.location.hash === '#/work/shift', key: 'work-pattern.shift-day.index' }
        ]
      }
    ]
  },
  {
    menucontent: 'Payroll Calculate',
    category: 'manageEmployee',
    Items: [
      {
        title: LangPayroll,
        icon: DollarSign,
        type: 'sub',
        active: ['#/payrolls/calculate', '#/payrolls/paid', '#/payrolls/paid-stats'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/payrolls/calculate`, title: LangCalculate, type: 'link', active: window.location.hash === '#/payrolls/calculate', key: 'payroll.calculate.index' },
          { path: `${process.env.PUBLIC_URL}/payrolls/paid`, title: LangPaid, type: 'link', active: window.location.hash === '#/payrolls/paid', badge: <Badges releaseDate='2022-12-12' />, key: 'payroll.paid.index' },
          { path: `${process.env.PUBLIC_URL}/payrolls/paid-stats`, title: LangSalaryPaidStatistics, type: 'link', active: window.location.hash === '#/payrolls/paid-stats', badge: <Badges releaseDate='2024-11-15' />, key: 'payroll.paid-stats.index' }
        ],
        badge: <BadgesParent releaseDate='2024-11-15' limitation={[]} checkAuthorization={['payroll.paid-stats.index']} />
      }
    ]
  },
  {
    menucontent: 'Client Visit',
    category: 'manageEmployee',
    Items: [
      {
        title: LangClientVisit,
        icon: MapPin,
        type: 'sub',
        active: ['#/client', '#/client-schedule'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/client`, title: LangClient, type: 'link', active: window.location.hash === '#/client', badge: <Badges releaseDate='2022-12-12' />, key: 'client-visit.client.index' },
          { path: `${process.env.PUBLIC_URL}/visit-schedule`, title: LangVisitSchedule, type: 'link', active: window.location.hash === '#/visit-schedule', badge: <Badges releaseDate='2022-12-12' />, key: 'client-visit.schedule.index' }
        ]
      }
    ]
  },
  {
    menucontent: 'Timesheet',
    category: 'manageEmployee',
    Items: [
      {
        title: LangTimesheet,
        icon: <FontAwesomeIcon className='txt-dark' icon={faBusinessTime} />,
        type: 'sub',
        active: ['#/timesheet/activity'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/timesheet/activity`, title: LangActivity, type: 'link', active: window.location.hash === '#/timesheet/activity', key: 'timesheet.index' },
          { path: `${process.env.PUBLIC_URL}/timesheet/project`, title: LangProject, type: 'link', active: window.location.hash === '#/timesheet/project', key: 'timesheet.project.index' },
        ],
        badge: <Badges releaseDate='2024-07-09' />
      }
    ]
  },
  {
    menucontent: 'Broadcast',
    category: 'manageEmployee',
    Items: [
      {
        title: 'Broadcast',
        icon: Rss,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/broadcast`,
        active: window.location.hash === '#/broadcast',
        key: 'broadcast.index'
      }
    ]
  },
  {
    menucontent: 'Settings',
    category: 'adjustment',
    Items: [
      {
        title: LangSetting,
        icon: Settings,
        type: 'sub',
        active: ['#/company/setting', '#/calendar-holiday', '#/company/mobile', '#/company/overtime-calculation', '#/company/special-leave', '#/claim/type', '#/company/reset', '#/company/BPJS'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/company/setting`, title: CompanySetting, type: 'link', active: window.location.hash === '#/company/setting', key: 'company-profile.index' },
          { path: `${process.env.PUBLIC_URL}/company/mobile`, title: MobileAplication, type: 'link', active: window.location.hash === '#/company/mobile', key: 'mobile-app.index' },
          { path: `${process.env.PUBLIC_URL}/company/BPJS`, title: LangBPJS, type: 'link', active: window.location.hash === '#/company/BPJS', badge: <Badges releaseDate='2024-11-12' />, key: 'company-bpjs.index' },
          { submenucontent: 'Overtime Calculation', path: `${process.env.PUBLIC_URL}/company/overtime-calculation`, title: LangOvertimeCalculation, type: 'link', active: window.location.hash === '#/company/overtime-calculation', key: 'overtime-wage-rule.index' },
          { submenucontent: 'Special Leave', path: `${process.env.PUBLIC_URL}/company/special-leave`, title: LangSpecialLeave, type: 'link', active: window.location.hash === '#/company/special-leave', badge: <Badges releaseDate='2024-03-06' />, key: 'special-leave.index' },
          { submenucontent: 'Approval Rules', path: `${process.env.PUBLIC_URL}/company/approval-rules`, title: LangApprovalRules, type: 'link', active: window.location.hash === '#/company/approval-rules', badge: <Badges releaseDate='2024-05-03' />, key: 'approval-rules.index' },
          { path: `${process.env.PUBLIC_URL}/calendar-holiday`, title: LangHolidayCalendar, type: 'link', active: window.location.hash === '#/calendar-holiday', childs: ['/calendar-holiday'], key: 'holiday-calendar.index' },
          { submenucontent: 'Claim Type', path: `${process.env.PUBLIC_URL}/claim/type`, title: LangClaimType, type: 'link', active: window.location.hash === '#/claim/type', childs: ['/claim/type/:id/employees'], key: 'claim.type.index' },
          { path: `${process.env.PUBLIC_URL}/admin-notification`, title: LangAdminNotification, type: 'link', active: window.location.hash === '#/admin-notification', badge: <Badges releaseDate='2025-01-13' />, key: 'company-admin-notification.index' }
        ], 
        badge: <BadgesParent releaseDate='2025-01-13' limitation={[]} checkAuthorization={[]} />
      }
    ]
  },
  {
    menucontent: 'Subscribe',
    category: 'adjustment',
    Items: [
      {
        title: LangSubscription,
        icon: <FontAwesomeIcon className='txt-dark' style={{ fontSize: 18 }} icon={faRocket} />,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/subscribe`,
        active: window.location.hash === '#/subscribe',
        key: 'subscription.index'
      }
    ]
  },
  {
    menucontent: LangUserAdmin,
    category: 'adjustment',
    Items: [
      {
        title: LangUserAdmin,
        icon: <FontAwesomeIcon className='txt-dark' icon={faUserCog} />,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/admin`,
        active: window.location.hash === '#/admin',
        key: 'admin.index'
      }
    ]
  },
  {
    menucontent: LangUserSubAdmin,
    category: 'adjustment',
    Items: [
      {
        title: LangUserSubAdmin,
        icon: <FontAwesomeIcon className='txt-dark' icon={faUserFriends} />,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/sub-admin`,
        active: window.location.hash === '#/sub-admin', 
        childs: ['/sub-admin/role-management', '/sub-admin/role-management/add', '/sub-admin/role-management/edit/:id'],
        key: 'subadmin.index',
        badge: <Badges releaseDate='2024-12-31' />
      }
    ]
  },
  {
    menucontent: 'Pintu Kerjoo',
    category: 'adjustment',
    Items: [
      {
        title: LangAccPintuKerjoo,
        icon: Smartphone,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/pintu-kerjoo/manage-users`,
        active: window.location.hash === '#/pintu-kerjoo/manage-users',
        childs: ['/pintu-kerjoo/manage-users/:id/detail', '/pintu-kerjoo/manage-users/edit', '/pintu-kerjoo/manage-users/add'],
        key: 'pintu-kerjoo.account'
      }
    ]
  },
  {
    menucontent: 'Pintu Kerjoo Landing Page',
    category: 'adjustment',
    Items: [
      {
        title: LangAccPintuKerjoo,
        icon: Smartphone,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/pintu-kerjoo`,
        active: window.location.hash === '#/pintu-kerjoo'
      }
    ]
  },
  {
    menucontent: 'Premium Features',
    category: 'system',
    Items: [
      {
        title: LangPremiumFeatures,
        icon: Star,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/premium-features`,
        active: window.location.hash === '#/premium-features',
        badge: <Badges releaseDate='2022-12-12' />
      }
    ]
  },
  {
    menucontent: 'Developer',
    category: 'system',
    Items: [
      {
        title: LangDeveloper,
        icon: Code,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/developer`,
        active: window.location.hash === '#/developer'
      }
    ]
  },
  {
    menucontent: 'Usage Guide',
    category: 'system',
    Items: [
      {
        title: LangUsageGuide,
        icon: <FontAwesomeIcon className='txt-dark' icon={faBookOpen} />, // use fontawesome
        type: 'external-link',
        path: `${process.env.REACT_APP_DOCS_URL}docs/${activeLang}/introduction`
      }
    ]
  },
  {
    menucontent: 'Reset',
    category: 'system',
    Items: [
      {
        title: LangResetData,
        icon: Trash2,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/reset`,
        active: window.location.hash === '#/reset'
      }
    ]
  }
]

export const MENUITEMS = [
  {
    menucontent: 'Dashboard',
    Items: [
      {
        title: Dashboard, icon: Home, path: `${process.env.PUBLIC_URL}/dashboard`, type: 'link', active: !!(window.location.hash === '#/dashboard' || window.location.hash === ''), key: 'dashboard.index'
      }
    ]
  },
  {
    menucontent: 'Settings Employee',
    Items: [
      {
        title: ManageEmployees,
        icon: Users,
        type: 'sub',
        active: ['#/manage/group', '#/manage/position', '#/personnels', '#/attendance/spot', '#/personnel/work/time', '#/personnel/work/shift'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/manage/group`, title: ManageDivision, type: 'link', active: window.location.hash === '#/manage/group', childs: ['/manage/group', '/manage/group/:id/punishment'], key: 'group.index' },
          { path: `${process.env.PUBLIC_URL}/manage/position`, title: LangManagePosition, type: 'link', active: window.location.hash === '#/manage/position', childs: ['/manage/position', '/manage/position/visualization'], key: 'position.index'},
          { path: `${process.env.PUBLIC_URL}/personnels`, title: DataEmployees, type: 'link', active: window.location.hash === '#/personnels', childs: ['/personnels/create', '/personnels/deleted', '/personnels/:id/edit', '/personnels/:id/detail', '/personnels/:id/document'], key: 'employee.index' },
          { path: `${process.env.PUBLIC_URL}/attendance/spot`, title: AttendanceSpot, type: 'link', active: window.location.hash === '#/attendance/spot', key: 'spot.index' },
          { path: `${process.env.PUBLIC_URL}/personnel/work/time`, title: WorkTime, type: 'link', active: window.location.hash === '#/personnel/work/time', key: 'employee.workday-schedule.index' },
          { path: `${process.env.PUBLIC_URL}/personnel/work/shift`, title: ShiftWorkTime, type: 'link', active: window.location.hash === '#/personnel/work/shift', key: 'employee.shift-schedule.index' }
        ]
      }
    ]
  },
  {
    menucontent: 'Work Time',
    Items: [
      {
        title: WorkPattern,
        icon: Clock,
        type: 'sub',
        active: ['#/work/day', '#/work/shift'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/work/day`, title: WorkDay, type: 'link', active: window.location.hash === '#/work/day', key: 'work-pattern.work-day.index' },
          { path: `${process.env.PUBLIC_URL}/work/shift`, title: ShiftDay, type: 'link', active: window.location.hash === '#/work/shift', key: 'work-pattern.shift-day.index' }
        ]
      }
    ]
  },
  {
    menucontent: 'Attendance',
    Items: [
      {
        title: LangAttendance,
        icon: MapPin,
        type: 'sub',
        active: ['#/daily/attendance', '#/attendance/summary', '#/attendance/review', '#/attendance/revision', '#/attendance-approval', '#/device-approval', '#/shift-change-schedule-recap', '#/attendance/profile-picture-approval'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/daily/attendance`, title: DailyAttendance, type: 'link', active: window.location.hash === '#/daily/attendance', key: 'daily-attendance.index' },
          { path: `${process.env.PUBLIC_URL}/attendance/summary`, title: AttendenceSummary, type: 'link', active: window.location.hash === '#/attendance/summary', key: 'attendance-summary.index' },
          { path: `${process.env.PUBLIC_URL}/attendance/review`, title: AttendanceReview, type: 'link', active: window.location.hash === '#/attendance/review', badge: <BadgeAttendanceReview />, key: 'attendance-review.index' },
          { path: `${process.env.PUBLIC_URL}/attendance/revision`, title: LangAttendanceRevision, type: 'link', active: window.location.hash === '#/attendance/revision', badge: <BadgeAttendanceRevision />, key: 'attendance-revision.index' },
          { path: `${process.env.PUBLIC_URL}/attendance-approval`, title: LangAttendanceApproval, type: 'link', active: window.location.hash === '#/attendance-approval', badge: <BadgeAttendanceApproval />, key: 'attendance-approval.index' },
          { submenucontent: 'Device Approval', path: `${process.env.PUBLIC_URL}/device-approval`, title: LangDeviceApproval, type: 'link', active: window.location.hash === '#/device-approval', badge: <BadgeDeviceApproval />, key: 'device-approval.index' },
          { submenucontent: 'Profile Picture Approval', path: `${process.env.PUBLIC_URL}/attendance/profile-picture-approval`, title: moment().isBefore(moment('2024-10-30')) ? LangProfileApproval : LangProfilePhotoApproval, type: 'link', active: window.location.hash === '#/attendance/profile-picture-approval', badge: <><Badges releaseDate='2024-09-30' /><BadgeAttendancePhotoProfileApproval /></>, key: 'picture-approval.index' },
          { path: `${process.env.PUBLIC_URL}/shift-change-schedule-recap`, title: LangShiftChangeScheduleRecap, type: 'link', active: window.location.hash === '#/shift-change-schedule-recap', key: 'switch-shift-recap.index' }
        ],
        badge: <><Badges releaseDate='2024-09-30' /><BadgeAttendanceParentMenuV1 /></>
      }
    ]
  },
  {
    menucontent: 'Pintu Kerjoo',
    Items: [
      {
        title: 'Pintu Kerjoo',
        icon: Tablet,
        type: 'sub',
        active: ['#/pintu-kerjoo/attendance-approval', '#/pintu-kerjoo/manage-users' , '#/pintu-kerjoo/attendance-sync-data'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/pintu-kerjoo/manage-users`, title: LangAccPintuKerjoo, type: 'link', active: window.location.hash === '#/pintu-kerjoo/manage-users', childs: ['/pintu-kerjoo/manage-users/:id/detail', '/pintu-kerjoo/manage-users/edit', '/pintu-kerjoo/manage-users/add'], key: 'pintu-kerjoo.account' },
          { path: `${process.env.PUBLIC_URL}/pintu-kerjoo/attendance-approval`, title: LangAttendanceApproval, type: 'link', active: window.location.hash === '#/pintu-kerjoo/attendance-approval', badge: <BadgePintuKerjoo />, key: 'pintu-kerjoo.approval' },
          { path: `${process.env.PUBLIC_URL}/pintu-kerjoo/attendance-sync-data`, title: LangAttendanceSyncData, type: 'link', active: window.location.hash === '#/pintu-kerjoo/attendance-sync-data', key: 'pintu-kerjoo.sync.index' }
        ],
        badge: <BadgePintuKerjoo />
      }
    ]
  },
  {
    menucontent: 'Pintu Kerjoo Landing Page',
    Items: [
      {
        title: 'Pintu Kerjoo',
        icon: Tablet,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/pintu-kerjoo`,
        active: window.location.hash === '#/broadcast/pintu-kerjoo'
      }
    ]
  },
  {
    menucontent: 'Overtime',
    Items: [
      {
        title: LangOvertimeSalary,
        icon: Info,
        type: 'sub',
        active: ['#/overtime-review', '#/overtime-schedule'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/overtime-review`, title: LangOvertimeReview, type: 'link', active: window.location.hash === '#/overtime-review', childs: ['/overtime-review/rejection-history'], key: 'overtime.review.index' },
          { path: `${process.env.PUBLIC_URL}/overtime-schedule`, title: LangOvertimeSchedule, type: 'link', active: window.location.hash === '#/overtime-schedule', badge: <Badges releaseDate='2024-07-29' />, key: 'overtime.schedule.index' }
        ],
        badge: <BadgesParent releaseDate='2024-07-29' limitation={[]}/>
      }
    ]
  },
  {
    menucontent: 'Permit',
    Items: [
      {
        title: LangUnpaidLeave,
        icon: PauseCircle,
        type: 'sub',
        active: ['#/permit/request', '#/permithour-review'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/permit/request`, title: LangLeaveDay, type: 'link', active: window.location.hash === '#/permit/request', badge: <><Badges releaseDate='2022-12-12' /> <BadgePermitRequest /></>, key: 'unpaid-leave.day.index' },
          { path: `${process.env.PUBLIC_URL}/permithour-review`, title: LangPermitHourReview, type: 'link', active: window.location.hash === '#/permithour-review', key: 'unpaid-leave.hour.index' }
        ],
        badge: <><Badges releaseDate='2022-12-12' /> <BadgePermitRequest /></>
      }
    ]
  },
  {
    menucontent: 'Leave Request',
    Items: [
      {
        title: LangMenuLeaveRequest,
        icon: PauseCircle,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/leave/request`,
        active: window.location.hash === '#/leave/request',
        badge: <BadgeLeaveRequest />, 
        key: 'paid-leave.day.index'
      }
    ]
  },
  {
    menucontent: 'Claim',
    Items: [
      {
        title: LangClaim,
        icon: <FontAwesomeIcon className='txt-dark' icon={faHandHoldingUsd} />,
        type: 'sub',
        active: ['#/claim/request', '#/claim/type'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/claim/request`, title: LangClaimRequest, type: 'link', active: window.location.hash === '#/claim/request', badge: <BadgeClaimRequest />, key: 'claim.request.index' },
          { path: `${process.env.PUBLIC_URL}/claim/type`, title: LangClaimType, type: 'link', active: window.location.hash === '#/claim/type', childs: ['/claim/type/:id/employees'], key: 'claim.type.index' }
        ],
        badge: <><Badges releaseDate='2024-03-06' /> <BadgeClaimRequest /></>
      }
    ]
  },
  {
    menucontent: 'Client Visit',
    Items: [
      {
        title: LangClientVisit,
        icon: Briefcase,
        type: 'sub',
        active: ['#/client-visit', '#/visit-summary', '#/client', '#/client-schedule', '#/client-visit-visualization'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/client-visit`, title: LangVisit, type: 'link', active: window.location.hash === '#/client-visit', key: 'client-visit.index' },
          { path: `${process.env.PUBLIC_URL}/client-visit-visualization`, title: LangClientVisitVisualization, type: 'link', active: window.location.hash === '#/client-visit-visualization', key: 'client-visit.visualization.index' },
          { path: `${process.env.PUBLIC_URL}/visit-summary`, title: LangVisitSummary, type: 'link', active: window.location.hash === '#/visit-summary', key: 'client-visit.summary.index' },
          { path: `${process.env.PUBLIC_URL}/client`, title: LangClient, type: 'link', active: window.location.hash === '#/client', badge: <Badges releaseDate='2022-12-12' />, key: 'client-visit.client.index' },
          { path: `${process.env.PUBLIC_URL}/visit-schedule`, title: LangVisitSchedule, type: 'link', active: window.location.hash === '#/visit-schedule', badge: <Badges releaseDate='2022-12-12' />, key: 'client-visit.schedule.index' }
        ]
      }
    ],
    badge: <Badges releaseDate='2022-12-12' />
  },
  {
    menucontent: 'Timesheet',
    Items: [
      {
        title: LangTimesheet,
        icon: <FontAwesomeIcon className='txt-dark' icon={faBusinessTime} />,
        type: 'sub',
        active: ['#/timesheet/activity'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/timesheet/activity`, title: LangActivity, type: 'link', active: window.location.hash === '#/timesheet/activity', key: 'timesheet.index' },
          { path: `${process.env.PUBLIC_URL}/timesheet/project`, title: LangProject, type: 'link', active: window.location.hash === '#/timesheet/project', key: 'timesheet.project.index' },
        ],
        badge: <Badges releaseDate='2024-07-09' />
      }
    ]
  },
  {
    menucontent: 'Broadcast',
    Items: [
      {
        title: 'Broadcast',
        icon: Rss,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/broadcast`,
        active: window.location.hash === '#/broadcast',
        key: 'broadcast.index'
      }
    ]
  },
  {
    menucontent: 'Payroll Calculate',
    Items: [
      {
        title: LangPayroll,
        icon: DollarSign,
        type: 'sub',
        active: ['#/payrolls/calculate', '#/payrolls/paid', '#/payrolls/paid-stats'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/payrolls/calculate`, title: LangCalculate, type: 'link', active: window.location.hash === '#/payrolls/calculate', key: 'payroll.calculate.index' },
          { path: `${process.env.PUBLIC_URL}/payrolls/paid`, title: LangPaid, type: 'link', active: window.location.hash === '#/payrolls/paid', badge: <Badges releaseDate='2022-12-12' />, key: 'payroll.paid.index' },
          { path: `${process.env.PUBLIC_URL}/payrolls/paid-stats`, title: LangSalaryPaidStatistics, type: 'link', active: window.location.hash === '#/payrolls/paid-stats', badge: <Badges releaseDate='2024-11-15' />, key: 'payroll.paid-stats.index' }
        ],
        badge: <BadgesParent releaseDate='2024-11-15' limitation={[]} checkAuthorization={['payroll.paid-stats.index']} />
      }
    ]
  },
  {
    menucontent: 'Settings',
    Items: [
      {
        title: LangSetting,
        icon: Settings,
        type: 'sub',
        active: ['#/company/setting', '#/admin', '#/subscribe', '#/calendar-holiday', '#/company/mobile', '#/company/overtime-calculation', '#/company/special-leave', '#/company/approval-rules', '#/company/reset', '#/company/BPJS', '#/sub-admin'].includes(window.location.hash),
        children: [
          { path: `${process.env.PUBLIC_URL}/company/setting`, title: CompanySetting, type: 'link', active: window.location.hash === '#/company/setting', key: 'company-profile.index' },
          { path: `${process.env.PUBLIC_URL}/company/mobile`, title: MobileAplication, type: 'link', active: window.location.hash === '#/company/mobile', key: 'mobile-app.index' },
          { path: `${process.env.PUBLIC_URL}/company/BPJS`, title: LangBPJS, type: 'link', active: window.location.hash === '#/company/BPJS', badge: <Badges releaseDate='2024-11-12' />, key: 'company-bpjs.index' },
          { submenucontent: 'Overtime Calculation', path: `${process.env.PUBLIC_URL}/company/overtime-calculation`, title: LangOvertimeCalculation, type: 'link', active: window.location.hash === '#/company/overtime-calculation', key: 'overtime-wage-rule.index' },
          { submenucontent: 'Special Leave', path: `${process.env.PUBLIC_URL}/company/special-leave`, title: LangSpecialLeave, type: 'link', active: window.location.hash === '#/company/special-leave', badge: <Badges releaseDate='2024-03-06' />, key: 'special-leave.index' },
          { submenucontent: 'Approval Rules', path: `${process.env.PUBLIC_URL}/company/approval-rules`, title: LangApprovalRules, type: 'link', active: window.location.hash === '#/company/approval-rules', badge: <Badges releaseDate='2024-05-03' />, key: 'approval-rules.index' },
          { path: `${process.env.PUBLIC_URL}/calendar-holiday`, title: LangHolidayCalendar, type: 'link', active: window.location.hash === '#/calendar-holiday', childs: ['/calendar-holiday'], key: 'holiday-calendar.index' },
          { path: `${process.env.PUBLIC_URL}/subscribe`, title: LangMenuSubscribeInfo, type: 'link', active: window.location.hash === '#/subscribe', key: 'subscription.index' },
          { submenucontent: LangUserAdmin, path: `${process.env.PUBLIC_URL}/admin`, title: 'Admin', type: 'link', active: window.location.hash === '#/admin', key: 'admin.index' },
          { submenucontent: LangUserSubAdmin, path: `${process.env.PUBLIC_URL}/sub-admin`, title: 'Sub-Admin', type: 'link', active: window.location.hash === '#/sub-admin', childs: ['/sub-admin/role-management', '/sub-admin/role-management/add', '/sub-admin/role-management/edit/:id'], badge: <Badges releaseDate='2024-12-31' />, key: 'subadmin.index' },
          { path: `${process.env.PUBLIC_URL}/admin-notification`, title: LangAdminNotification, type: 'link', active: window.location.hash === '#/admin-notification', badge: <Badges releaseDate='2025-01-13' />, key: 'company-admin-notification.index' }
        ],
        badge: <BadgesParent releaseDate='2025-01-13' limitation={[]} checkAuthorization={[]} />
      }
    ]
  },
  {
    menucontent: 'Premium Features',
    Items: [
      {
        title: LangPremiumFeatures,
        icon: Star,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/premium-features`,
        active: window.location.hash === '#/premium-features',
        badge: <Badges releaseDate='2022-12-12' />
      }
    ]
  },
  {
    menucontent: 'Developer',
    Items: [
      {
        title: LangDeveloper,
        icon: Code,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/developer`,
        active: window.location.hash === '#/developer'
      }
    ]
  },
  {
    menucontent: 'Usage Guide',
    Items: [
      {
        title: LangUsageGuide,
        icon: <FontAwesomeIcon className='txt-dark' style={{ fontSize: 16 }} icon={faFilePdf} />, // use fontawesome
        type: 'external-link',
        path: `${process.env.REACT_APP_DOCS_URL}docs/${activeLang}/introduction`
      }
    ]
  },
  {
    menucontent: 'Reset',
    Items: [
      {
        title: LangResetData,
        icon: Trash2,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/reset`,
        active: window.location.hash === '#/reset'
      }
    ]
  }
]

/**
 * SUPERVISOR MENU ITEMS
 * add menuContent name in supervisorMenu variabel to add new menu item
 */
const supervisorMenu = ['Dashboard', 'Settings Employee', 'Work Time', 'Attendance', 'Overtime', 'Permit', 'Leave Request', 'Client Visit', 'Broadcast', 'Review', 'Timesheet']
export const NEWMENUITEMSSUPERVISOR = NEWMENUITEMS.slice().filter(item => supervisorMenu.includes(item.menucontent))
export const MENUITEMSSUPERVISOR = MENUITEMS.slice().filter(item => supervisorMenu.includes(item.menucontent))

export const MENUOLDITEMS = [
  {
    menucontent: 'Dashboard',
    Items: [
      {
        title: Dashboard, icon: Home, path: `${process.env.PUBLIC_URL}/dashboard`, type: 'link', active: !!(window.location.hash === '#/dashboard' || window.location.hash === '')
      }
    ]

  },
  {
    menucontent: 'Company Setting',
    Items: [
      {
        title: CompanySetting, icon: Settings, path: `${process.env.PUBLIC_URL}/company/setting`, type: 'link', active: window.location.hash === '#/company/setting'
      }
    ]

  },

  {
    menucontent: 'Manage Admin',
    Items: [
      {
        title: ManageAdmin, icon: Unlock, path: `${process.env.PUBLIC_URL}/admin`, type: 'link', active: window.location.hash === '#/admin'
      }
    ]

  },

  {
    menucontent: 'Manage Group',
    Items: [
      {
        title: ManageDivision, icon: Users, path: `${process.env.PUBLIC_URL}/manage/group`, type: 'link', active: window.location.hash === '#/manage/group'
      }
    ]

  },

  {
    menucontent: 'Attendance Spot',
    Items: [
      {
        title: AttendanceSpot, icon: Radio, path: `${process.env.PUBLIC_URL}/attendance/spot`, type: 'link', active: window.location.hash === '#/attendance/spot'
      }
    ]

  },

  {
    menucontent: 'Work Pattern',
    Items: [
      {
        title: WorkPattern,
        icon: User,
        type: 'sub',
        active: window.location.hash === '#/work/day' || window.location.hash === '#/work/shift',
        children: [
          { path: `${process.env.PUBLIC_URL}/work/day`, title: WorkDay, type: 'link', active: window.location.hash === '#/work/day' },
          { path: `${process.env.PUBLIC_URL}/work/shift`, title: ShiftDay, type: 'link', active: window.location.hash === '#/work/shift' }
        ]
      }
    ]

  },

  {
    menucontent: 'Manage Personnel',
    Items: [
      {
        title: ManageEmployees,
        icon: User,
        type: 'sub',
        active: window.location.hash === '#/personnel/data' || window.location.hash === '#/personnel/group' || window.location.hash === '#/personnel/work/time' || window.location.hash === '#/personnel/work/shift',
        children: [
          { path: `${process.env.PUBLIC_URL}/personnel/data`, title: DataEmployees, type: 'link', active: window.location.hash === '#/personnel/data' },
          { path: `${process.env.PUBLIC_URL}/personnel/work/time`, title: WorkTime, type: 'link', active: window.location.hash === '#/personnel/work/time' },
          { path: `${process.env.PUBLIC_URL}/personnel/work/shift`, title: ShiftWorkTime, type: 'link', active: window.location.hash === '#/personnel/work/shift' }
        ]
      }
    ]
  },

  // {
  //   menucontent: 'Timesheet',
  //   Items: [
  //     {
  //       title: Timesheet,
  //       icon: Check,
  //       type: 'sub',
  //       active: window.location.hash === '#/timesheet/list' || window.location.hash === '#/timesheet/report',
  //       children: [
  //         { path: `${process.env.PUBLIC_URL}/timesheet/list`, title: TimesheetList, type: 'link', active: window.location.hash === '#/timesheet/list' },
  //         { path: `${process.env.PUBLIC_URL}/timesheet/report`, title: TimesheetReport, type: 'link', active: window.location.hash === '#/timesheet/report' }
  //       ]
  //     }
  //   ]
  // },

  {
    menucontent: 'Daily Attendance',
    Items: [
      {
        title: DailyAttendance,
        icon: Clock,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/daily/attendance`,
        active: window.location.hash === '#/daily/attendance'
      }
    ]
  },

  {
    menucontent: 'Attendence Summary',
    Items: [
      {
        title: AttendenceSummary,
        icon: File,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/attendance/summary`,
        active: window.location.hash === '#/attendance/summary'
      }
    ]
  },

  {
    menucontent: 'Attendence Review',
    Items: [
      {
        title: AttendanceReview,
        icon: Info,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/attendance/review`,
        active: window.location.hash === '#/attendance/review',
        badge: <BadgeAttendanceReview />
      }
    ]
  },

  {
    menucontent: 'Leave Request',
    Items: [
      {
        title: LangMenuLeaveRequest,
        icon: PauseCircle,
        type: 'link',
        path: `${process.env.PUBLIC_URL}/leave/request`,
        active: window.location.hash === '#/leave/request',
        badge: <BadgeLeaveRequest />
      }
    ]
  }

]
