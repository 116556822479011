import React, { useEffect, useState } from 'react'
import { LangNew } from '../../constant/languange'
import { Spinner9 } from '../../helpers/Spinner'
import { checkAuthorization, connect, mapStateToProps, moment } from '../../helpers/Common'

/**
 *
 * @param releaseDate date:'YYYY-MM-DD'
 * @param DefaultBadge component
 * @returns component
 */
function BadgesParent({ type = 'link', releaseDate = '', DefaultBadge = null, redux, ...props }) {
  const [control, setControl] = useState({ newBadgeExpired: true, show: true })

  useEffect(() => {
    setControl({ ...control, newBadgeExpired: releaseDate !== '' ? moment().isSameOrAfter(moment(releaseDate).add(1, 'month'), 'day') : true })
    if (props.limitation) {
      if (props.limitation.length !== 0) {
        props.limitation.forEach(item => {
          if (redux.hasOwnProperty(item)) {
            if (redux[item] === false) setControl({ ...control, show: false })
          }
        })
      }
    }
    if (props.checkAuthorization) {
      if (props.checkAuthorization.length !== 0) {
        props.checkAuthorization.forEach(item => {
          if (!checkAuthorization(redux, item)) {
            setControl({ ...control, show: false })
          }
        })
      }
    }
    if (props.checkRoleAuthorization) {
      if (redux.profile.role_id !== props.checkRoleAuthorization) {
        setControl({ ...control, show: false })
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {!control.newBadgeExpired && control.show
        ? <div style={{ fontSize: '9px' }} className={`badge badge-secondary ${type === 'sub' && 'mr-3'} position-static py-1 d-flex align-items-center mr-1`}>{Spinner9({ size: 9 })} <span className='font-weight-bold' style={{ color: 'inherit' }}>{LangNew.toUpperCase()}</span></div>
        : DefaultBadge}
    </>
  )
}

export default connect(mapStateToProps)(BadgesParent)
