import React, { useEffect, useState } from 'react'
import { getScheduleValues } from '../../constant/WorkPatternsConstant'
import { destructuringObjectFields } from '../../helpers/Common'
import HistoryItemChangeFieldComponent from '../common/HistoryItemChangeFieldComponent'
import HistoryItemFieldComponent from './HistoryItemFieldComponent'
import { getAdditionalFieldsValues } from '../../constant/ClientVisitClientConstant'
import { checkAttrValue } from './CheckAttrActivityLog/checker'
import { LangNo, LangYes } from '../../constant/languange'

export const HistoryItemComponent = ({ data, ...props }) => {
  // check attr value equality
  if (data.action_name === 'modify') {
    checkAttrValue(data, 'emergency_contacts', 'arrayofobject') // personnel/emergency contact
    checkAttrValue(data, 'auto_reload_annual_leave', 'object') // personnel/auto reload cuti
    checkAttrValue(data, 'approval_steps', 'arrayofobject') // leave-request/approval steps
  }

  const {
    // name = null,
    attr = null
  } = data.data
  const { subject = {} } = data

  const [dataChange, setDataChange] = useState([])

  useEffect(() => {
    handleDataChanging()
    // eslint-disable-next-line
  }, [data.data])

  const handleDataChanging = () => {
    // checking if attr fields exist
    // if exists fields inside of attr will destructuring into history format
    const attrChangingFields = (data?.action_name === 'modify' && attr && subject?.obj_attr_fields) ? destructuringObjectFields(attr, subject?.obj_attr_fields ?? {}) : null

    const fieldValue = { ...data.data, ...attrChangingFields, ...handleCertainData() }
    const fields = Object.keys(subject?.changing_fields ?? {}).map((key, i) => {
      return ({
        label: subject?.changing_fields ? subject?.changing_fields[key] || '' : '',
        value: fieldValue[key] ? subject?.formated_fields ? subject?.formated_fields(fieldValue, key) : fieldValue[key] : null
      })
    })

    setDataChange([...fields])
  }

  const handleCertainData = () => {
    if (subject?.value === 'WorkPattern') {
      if (data.data.schedules != null) {
        return { schedules: getScheduleValues(data.data.schedules) }
      }
    }

    if (subject?.value === 'ClientVisitClient') {
      if (data.data.additional_fields != null) {
        return { additional_fields: getAdditionalFieldsValues(data.data.additional_fields) }
      }
    }

    if (subject?.value === 'Permit') {
      if (data.action_name === 'add') {
        const payrollDeduction = [LangYes, LangNo]
        return { is_exclude_deduction: data.data.is_exclude_deduction !== null ? payrollDeduction[data.data.is_exclude_deduction] : null }
      }
    }
  }

  return (
    <>
      <div>
        {dataChange.map((item, i) => {
          return (
            <React.Fragment key={i}>
              {data.action_name === 'modify'
                ? (<HistoryItemChangeFieldComponent item={item} />)
                : (<HistoryItemFieldComponent item={item} />)}
            </React.Fragment>
          )
        })}
      </div>
    </>
  )
}

export default HistoryItemComponent
