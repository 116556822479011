import { faUserSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { LangEmployeeDeletedAt, LangPosition, LangDivision, LangGenBabyBoomers, LangGenX, LangGenY, LangGenAlpha, LangGenZ, LangEmailVerified, LangEmailVerificationRequired } from '../../constant/languange'
import { checkAuthorization, connect, displayDate, displayTimezone, mapStateToProps, ShowImage } from '../../helpers/Common'
import ReactTooltip from 'react-tooltip'
import { useHistory } from 'react-router-dom'

function PersonnelItemComponent ({ data, childrenMediaBody = null, listType, redux, ...props }) {
  const history = useHistory()
  const [timezone, setTimezone] = useState('');

  useEffect(() => {
    if(data) {
      setTimezone(data.attr?.user?.timezone || data.timezone)
    }
  }, [])
  

  const gen = (birthYear) => {
    let badge = ''
    switch (true) {
      case (birthYear >= 1946 && birthYear <= 1964): {
        badge = LangGenBabyBoomers
        break
      }
      case (birthYear >= 1965 && birthYear <= 1976): {
        badge = LangGenX
        break
      }
      case (birthYear >= 1977 && birthYear <= 1994): {
        badge = LangGenY
        break
      }
      case (birthYear >= 1995 && birthYear <= 2010): {
        badge = LangGenZ
        break
      }
      case (birthYear >= 2011 && birthYear <= 2025): {
        badge = LangGenAlpha
        break
      }
      default: {
        badge = ''
      }
    }
    return badge
  }

  const styleOverflow = {
    fontSize: '.8rem',
    maxWidth: '110px', 
    whiteSpace: 'nowrap', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis'
  }

  const linkToPersonnelDetail = () => {
    if (!checkAuthorization(redux, 'employee.index')) {
      return
    }
    if (props.origin === 'personnelList' && data?.user) {
      if (data?.user?.email_verified_at) {
        history.push(`/personnels/${data.id}/detail`)
      } else {
        history.push(`/personnels/${data.id}/detail#email`)
      }
    } else {
      history.push(`/personnels/${data.id}/detail`)
    }
  }

  return (
    <div className='media align-items-center'>
      <div className='media-size-email'>
        <ShowImage style={{ cursor: checkAuthorization(redux, 'employee.index') ? 'pointer' : '' }} onClick={() => { { checkAuthorization(redux, 'employee.index') && history.push(`/personnels/${data.id}/detail`) } }} className='mr-3 img-50 rounded-circle' url={data?.picture_url?.s ? data?.picture_url?.s : null} defaultpictureonload={1} />
      </div>
      <div className='media-body' style={{ width: '70%' }}>
        <a className={`txt-dark f-w-500 ${checkAuthorization(redux, 'employee.index') ? 'btn-link' : ''}`} style={{ cursor: checkAuthorization(redux, 'employee.index') ? 'pointer' : '', fontSize: '1rem' }} onClick={linkToPersonnelDetail}>
          {data?.name ?? ''}
          {
            props.origin === 'personnelList' && data?.user ?
              data?.user?.email_verified_at ?
                <span style={{ color: '#28a745', marginLeft: '.25rem' }} data-for={`tooltip-user-${data?.id}`} data-tip={LangEmailVerified}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="m23 12l-2.44-2.78l.34-3.68l-3.61-.82l-1.89-3.18L12 3L8.6 1.54L6.71 4.72l-3.61.81l.34 3.68L1 12l2.44 2.78l-.34 3.69l3.61.82l1.89 3.18L12 21l3.4 1.46l1.89-3.18l3.61-.82l-.34-3.68zm-13 5l-4-4l1.41-1.41L10 14.17l6.59-6.59L18 9z"/></svg>
                </span>
                :
                <span style={{ color: '#6c757d', marginLeft: '.25rem' }} data-for={`tooltip-user-${data?.id}`} data-tip={LangEmailVerificationRequired}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M225.86 102.82c-3.77-3.94-7.67-8-9.14-11.57c-1.36-3.27-1.44-8.69-1.52-13.94c-.15-9.76-.31-20.82-8-28.51s-18.75-7.85-28.51-8c-5.25-.08-10.67-.16-13.94-1.52c-3.56-1.47-7.63-5.37-11.57-9.14C146.28 23.51 138.44 16 128 16s-18.27 7.51-25.18 14.14c-3.94 3.77-8 7.67-11.57 9.14c-3.25 1.36-8.69 1.44-13.94 1.52c-9.76.15-20.82.31-28.51 8s-7.8 18.75-8 28.51c-.08 5.25-.16 10.67-1.52 13.94c-1.47 3.56-5.37 7.63-9.14 11.57C23.51 109.72 16 117.56 16 128s7.51 18.27 14.14 25.18c3.77 3.94 7.67 8 9.14 11.57c1.36 3.27 1.44 8.69 1.52 13.94c.15 9.76.31 20.82 8 28.51s18.75 7.85 28.51 8c5.25.08 10.67.16 13.94 1.52c3.56 1.47 7.63 5.37 11.57 9.14c6.9 6.63 14.74 14.14 25.18 14.14s18.27-7.51 25.18-14.14c3.94-3.77 8-7.67 11.57-9.14c3.27-1.36 8.69-1.44 13.94-1.52c9.76-.15 20.82-.31 28.51-8s7.85-18.75 8-28.51c.08-5.25.16-10.67 1.52-13.94c1.47-3.56 5.37-7.63 9.14-11.57c6.63-6.9 14.14-14.74 14.14-25.18s-7.51-18.27-14.14-25.18M128 192a12 12 0 1 1 12-12a12 12 0 0 1-12 12m8-48.72v.72a8 8 0 0 1-16 0v-8a8 8 0 0 1 8-8c13.23 0 24-9 24-20s-10.77-20-24-20s-24 9-24 20v4a8 8 0 0 1-16 0v-4c0-19.85 17.94-36 40-36s40 16.15 40 36c0 17.38-13.76 31.93-32 35.28"/></svg>
                </span>
              : ''
          }
        </a>
        {/* <p className='mt-0 mb-2' style={{ lineHeight: '.9rem' }}>{data?.group ? (<><span className='badge badge-light border text-truncate' data-tip={LangDivision} style={{ maxWidth: '158px' }}>{data?.group?.name}</span> <br /></>) : ''} {data?.position ? <span data-tip={LangPosition}>{data.position}</span> : ''}</p> */}
        {/* {data?.group && <p className='mb-0' data-tip={LangDivision} style={{ maxWidth: '158px', fontSize: '.8rem' }}>{data?.group?.name}</p>}
        {data?.position && <p className='mb-0' style={{ lineHeight: '1.2', fontSize: '.8rem' }} data-tip={LangPosition}>{data.position}</p>} */}
        <p className='mb-0 d-flex align-items-center' style={{ lineHeight: '1.2'}}>
          {
            data?.position ?
              typeof data?.position === 'string' ?
                <label className='mb-0' style={data?.group ? styleOverflow : { fontSize: '.8rem' }} data-for={`tooltip-user-${data?.id}`} data-tip={`${LangPosition}: ${data.position ?? ''}`}>{data.position ?? ''}</label>
                :
                <label className='mb-0' style={data?.group ? styleOverflow : { fontSize: '.8rem' }} data-for={`tooltip-user-${data?.id}`} data-tip={`${LangPosition}: ${data.position?.name ?? ''}`}>{data.position?.name ?? ''}</label>
              :
              ''
          }
          {
            data?.group && data?.position && <label className='px-1 mb-0 font-weight-bold'>|</label>
          }
          {
            data?.group ?
              typeof data?.group === 'string' ?
              <label className='mb-0' style={data?.position ? styleOverflow : { fontSize: '.8rem' }} data-for={`tooltip-user-${data?.id}`} data-tip={`${LangDivision}: ${data.group ?? ''}`} >{data.group ?? ''}</label>
                :
                <label className='mb-0' style={data?.position ? styleOverflow : { fontSize: '.8rem' }} data-for={`tooltip-user-${data?.id}`} data-tip={`${LangDivision}: ${data.group?.name ?? ''}`} >{data.group?.name ?? ''}</label>
              :
              ''
          }
        </p>
        <p className='mb-0 d-flex align-items-center'>
          {
            timezone && <label className='mb-0' style={{ fontSize: '.8rem' }}>{displayTimezone(timezone)}</label>
          }
          {
            timezone && data?.birth_date && <label className='px-1 mb-0 font-weight-bold'>|</label>
          }
          {
            data?.birth_date && <label className='mb-0' style={{ fontSize: '.8rem' }}>{gen(Number(displayDate(data.birth_date, 'YYYY')))}</label>
          }
        </p>
        {(data?.deleted_at) ? <div data-for={`tooltip-user-${data?.id}`} data-tip={LangEmployeeDeletedAt + ' ' + displayDate(data.deleted_at, 'ddd, DD MMM YYYY @ HH:mm')} className='d-inline-block border rounded-sm border-danger outline alert-danger p-1 mb-2 mt-0' style={{ fontSize: 10 }}><FontAwesomeIcon icon={faUserSlash} /> {displayDate(data.deleted_at, 'ddd, DD MMM YYYY @ HH:mm')}</div> : null}
        {childrenMediaBody}
      </div>
      <ReactTooltip place='bottom' id={`tooltip-user-${data?.id}`}/>
    </div>
  )
}

export default connect(mapStateToProps)(PersonnelItemComponent)
