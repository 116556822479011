import { faEllipsisH, faPaperPlane, faPause, faPen, faPlus, faPlusSquare, faSearch, faShare, faSync, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState, useRef } from 'react'
import ReactTooltip from 'react-tooltip'
import { categoryReciver, statusBroadcast } from '../../constant/BroadcastConstant'
import { Action, Cancel, ChangeHistories, CreatedAt, Delete, Edit, EditData, LangBroadcastInfo, LangBroadcastReceiver, LangCancelConfirmation, LangCopy, LangCreator, LangDeleteSuccess, LangNewBroadcast, LangNoReceiver, LangOrderAddQuotaBroadcast, LangOtherReceiver, LangOutOfQuota, LangOutOfQuotaBroadcastMessage, LangPause, LangPaymentProcess, LangQuota, LangReceiver, LangSend, LangTestSend, LangTitle, LangViewContent, No, Search, Yes } from '../../constant/languange'
import { SpinnerButton, SpinnerLoadFirst } from '../../helpers/Spinner'
import Breadcrumbs from '../../layout/breadcrumb'
import BroadcastFormComponent from './BroadcastFormComponent'
import { axiosCompany, checkAuthorization, displayDate, loadDivisionOptions, loadPersonnelOptions, mapStateToProps, PaginationDiv, ShowImage, toRupiah } from '../../helpers/Common'
import { ModalApp, Form, AsyncSelectInput, ModalAction } from '../../helpers/Input'
import { Alert, Collapse } from 'react-bootstrap'
import ModalDelete from '../../helpers/Modal'
import BroadcastReceiverComponent from './BroadcastReceiverComponent'
import { connect } from 'react-redux'
import BroadcastQuotaComponent from './BroadcastQuotaComponent'
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap'
import BroadcastTestSendComponent from './BroadcastTestSendComponent'
import BroadcastPreviewComponent from './BroadcastPreviewComponent'
import OrderSummaryComponent from '../common/OrderSummaryComponent'
import ModalAdminActivityLogsComponent from '../admin/ModalAdminActivityLogsComponent'

function BroadcastComponent ({ redux, ...props }) {
  const [control, setControl] = useState({ showLoad: false, showForm: false, showModalDelete: false, showModalCancel: false, showModalReciver: false, showModalQuota: false, showModalTestSend: false, showModalPreview: false, showModalActivity: false })
  const [orderSummary, setOrderSummary] = useState({ showModal: false, data: {} })
  const [editData, setEditData] = useState(null)
  const [currentData, setCurrentData] = useState(null)
  const [status, setStatus] = useState({ value: '', id: '', index: '', error: '', showModal: false })
  const [data, setData] = useState({
    data: [],
    meta: {
      total: 0
    },
    stats: { canceled: 0, done: 0, paused: 0, quota_runs_out: 0, sending: 0, setup: 0 },
    error: {}
  })
  // console.log(data)
  const [filter, setFilter] = useState({
    keyword: '',
    page: 1,
    target: null,
    target_id: '',
    target_type: '',
    status: ''
  })
  const statusInterval = useRef(null)
  const initAlertData = { code: '', variant: '', message: '', show: false }

  useEffect(() => {
    getBroadcastsService()
    // eslint-disable-next-line
  }, [])

  // fetch broadcast api each minute if list of data contain 'sending' status
  useEffect(() => {
    if (checkStatus('sending')) {
      statusInterval.current = setInterval(() => {
        getBroadcastsService()
      }, 60000)
    } else {
      clearInterval(statusInterval.current)
    }
    return () => {
      clearInterval(statusInterval.current)
    }
    // eslint-disable-next-line
  }, [data.data])

  const getBroadcastsService = () => {
    // filtering spesific group or personnel
    if (filter.target_id !== '') filter.target_id.toString().split('')

    setControl(control => ({ ...control, showLoad: true }))
    axiosCompany().get('broadcasts', { params: filter }).then((ress) => {
      setData(data => {
        data = ress.data
        data.data = data.data.map(item => ({ ...item, load: false, alert: initAlertData }))
        return { ...data }
      })
      setControl(control => ({ ...control, showLoad: false, showForm: false }))
    }).catch(e => {
      setData(currData => ({ ...currData, error: e.response.data }))
      setControl(control => ({ ...control, showLoad: false }))
    })

    window.scrollTo(0, 0)
  }

  const deleteBroadcastService = () => {
    setControl({ ...control, showLoad: true })
    axiosCompany().delete(`broadcasts/${currentData.id}`).then(() => {
      getBroadcastsService()
      setControl({ ...control, showLoad: false, onSubmitDeleteResponse: { success: true } })
      setTimeout(() => {
        setControl({ ...control, showModalDelete: false, onSubmitDeleteResponse: {} })
      }, 1000)
    }).catch(e => {
      setControl(currControl => ({ ...currControl, showLoad: false, onSubmitDeleteResponse: e.response.data }))
    })
  }

  const statusBroadcastService = () => {
    setData(data => {
      data.data[status.index].load = true
      return { ...data }
    })
    axiosCompany().put(`broadcasts/${status.id}/status`, { status: status.value }).then(() => {
      getBroadcastsService()
      setData(data => {
        data.data[status.index].load = false
        return { ...data }
      })
      setControl({ ...control, showModalCancel: false })
    }).catch(e => {
      setData(data => {
        data.data[status.index].load = false
        data.data[status.index].alert = e.response.status === 404 ? { code: e.response.status, variant: 'info', message: 'Sepertinya ada perubahan pada data silakan muat ulang untuk mendapatkan data terkini', show: true } : { ...initAlertData, variant: 'danger', message: e.response.data.message, show: true }
        return { ...data }
      })
    })
  }

  const copyBroadcastService = (currentData, index) => {
    setData(data => {
      data.data[index].load = true
      return { ...data }
    })
    axiosCompany().post('broadcasts/copy', { broadcast_id: currentData.id }).then(() => {
      getBroadcastsService()
      setData(data => {
        data.data[index].load = false
        return { ...data }
      })
    }).catch(e => {
      setData(data => {
        data.data[index].load = false
        data.data[index].alert = { ...initAlertData, variant: 'danger', message: e.response.data.message, show: true }
        return { ...data }
      })
    })
  }

  const checkStatus = (status) => {
    const result = data.data.filter(item => item.status === status)
    return result.length > 0
  }

  const handleStatusChange = (value, id, index) => {
    if (value === 'canceled') {
      setStatus({ ...status, id: id, value: value, index: index })
      setControl({ ...control, showModalCancel: true })
    } else {
      status.value = value
      status.id = id
      status.index = index
      statusBroadcastService()
    }
  }

  const handlePreview = (data) => {
    setCurrentData({ ...data })
    setControl(control => ({ ...control, showModalPreview: true }))
  }

  const handleTestSend = (data) => {
    setCurrentData({ ...data })
    setControl(control => ({ ...control, showModalTestSend: true }))
  }

  const handleDelete = (data) => {
    setCurrentData({ ...data })
    setControl({ ...control, showModalDelete: true })
  }

  const handleKeydown = (e) => {
    if (e.key === 'Enter') {
      filter.page = 1
      getBroadcastsService()
    }
  }

  const handleStatusFilter = (status) => {
    filter.page = 1
    filter.status = status
    getBroadcastsService()
  }

  const handlePage = (page) => {
    filter.page = page
    getBroadcastsService()
  }

  const handleSearch = () => {
    filter.page = 1
    getBroadcastsService()
  }

  const handleForm = (data = null) => {
    data ? setEditData({ ...data }) : setEditData(data)
    setControl({ ...control, showForm: true })
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleAlertTable = (index) => {
    setData(data => {
      data.data[index].alert.show = false
      return { ...data }
    })
  }

  const handleModalReciver = (data) => {
    setControl({ ...control, showModalReciver: true })
    setCurrentData({ ...data })
  }

  return (
    <div>
      <Breadcrumbs title='Broadcast' child='Broadcast' />
      <div className='container-fluid'>
        <div className='row'>
          <div className='col col-md-12'>
            <Collapse in={control.showForm}>
              <div id='form-collapse' className='card'>
                <div className='card-header'>
                  <h5>{editData ? EditData : LangNewBroadcast}</h5>
                </div>
                <div className='card-body'>
                  <BroadcastFormComponent editData={editData} onUpdate={getBroadcastsService} handleClose={() => { setControl(control => ({ ...control, showForm: false })) }} />
                </div>
              </div>
            </Collapse>
          </div>
          <div className='col col-md-12'>
            <div className='card'>
              <div className='card-header'>
                <div className='row'>
                  <div className='col-12 col-xl-7'>
                    <div className='d-flex flex-column flex-sm-row flex-xl-row'>
                      <div className='mr-sm-2 mb-2'>
                        <Form.Control style={{ maxWidth: 320 }} name='target_type' as='select' onChange={(e) => { setFilter({ ...filter, target_type: e.target.value, target: null, target_id: '' }) }}>
                          <option value=''>{`-- ${LangReceiver} --`}</option>
                          {
                            categoryReciver.map((item, i) => (<option key={i} value={item.value}>{item.label}</option>))
                          }
                        </Form.Control>
                      </div>
                      {
                        filter.target_type === 'groups' && (
                          <div style={{ maxWidth: 280 }} className='form-control p-0 border-0 mr-sm-2 mb-2'>
                            <AsyncSelectInput
                              isLoading={false}
                              isClearable
                              loadOptions={loadDivisionOptions}
                              value={filter.target}
                              onChange={(data) => setFilter(filter => ({ ...filter, target_id: data?.value ? data?.value : '', target: data }))}
                            />
                          </div>
                        )
                      }
                      {
                        filter.target_type === 'personnels' && (
                          <div style={{ maxWidth: 280 }} className='form-control p-0 border-0 mr-sm-2 mb-2'>
                            <AsyncSelectInput
                              isLoading={false}
                              isClearable
                              loadOptions={loadPersonnelOptions}
                              value={filter.target}
                              onChange={(data) => setFilter(filter => ({ ...filter, target_id: data?.value ? data?.value : '', target: data }))}
                            />
                          </div>
                        )
                      }
                      <div className='mr-sm-2 mb-2'>
                        <div className='input-group'>
                          <input type='search' className='form-control' placeholder={Search} aria-describedby='button-addon2' value={filter.keyword} onKeyDown={handleKeydown} onChange={(e) => { setFilter({ ...filter, keyword: e.target.value }) }} style={{ maxWidth: 250 }} />
                          <button onClick={handleSearch} className='btn  btn-primary' style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }} disabled={control.showLoad} id='button-addon2'>
                            {control.showLoad ? SpinnerButton : <FontAwesomeIcon icon={faSearch} />}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-xl-5'>
                    {(redux.profile.supervisor_groups)
                      ? (
                        <div className='d-md-flex flex-column flex-sm-row flex-xl-wrap justify-content-xl-end'>
                          {(redux.broadcastQuota > 0)
                            ? (
                              <button className='btn btn-primary mb-2 mr-2 ' type='button' onClick={() => { (redux.broadcastQuota > 0) && handleForm() }}><FontAwesomeIcon icon={faPlusSquare} /> {LangNewBroadcast}</button>
                              )
                            : null}
                          <div className='btn-group mb-2'>
                            <button disabled className='btn btn-outline-success'><span className='text-truncate'>{LangQuota}: <b>{redux.broadcastQuota}</b></span> </button>
                          </div>
                        </div>
                        )
                      : (
                        <div className='d-md-flex flex-column flex-sm-row flex-xl-wrap justify-content-xl-end'>
                          {checkAuthorization(redux, 'broadcast.create') && (
                            <button id='Popover1' className='btn btn-primary mb-2 mr-2 ' type='button' onClick={() => { (redux.broadcastQuota > 0) && handleForm() }}><FontAwesomeIcon icon={faPlusSquare} /> {LangNewBroadcast}</button>
                          )}
                          {!redux.broadcastQuota > 0 && (
                            <UncontrolledPopover
                              placement='bottom'
                              target='Popover1'
                              trigger='legacy'
                            >
                              <PopoverHeader className='bg-light text-dark'>
                                {LangOutOfQuota}
                              </PopoverHeader>
                              <PopoverBody>
                                <p>
                                  {LangOutOfQuotaBroadcastMessage}
                                </p>
                              </PopoverBody>
                            </UncontrolledPopover>
                          )}

                          <div className='btn-group mb-2'>
                            {!redux.isTrial && checkAuthorization(redux, 'broadcast.buy-quota') && (
                              <button onClick={() => { setControl({ ...control, showModalQuota: true }) }} className='btn btn-success'><FontAwesomeIcon icon={faPlus} /></button>
                            )}
                            {checkAuthorization(redux, 'broadcast.index') && (
                              <button disabled className='btn btn-outline-success'><span className='text-truncate'>{LangQuota}: <b>{redux.broadcastQuota}</b></span> </button>
                            )}
                          </div>
                        </div>
                        )}
                  </div>
                  <div className='col-12'>
                    <div className='btn btn-group px-0 d-flex flex-column flex-md-row ' style={{ overflow: 'auto' }}>
                      {
                        Object.keys(statusBroadcast).map((key, i) => (
                          <button disabled={control.showLoad} key={i} className={`btn ${statusBroadcast[key].value === filter.status ? 'btn-primary' : redux.darkModeOn ? 'btn-dark' : 'btn-light'}`} onClick={() => { handleStatusFilter(statusBroadcast[key].value) }}>
                            {`${statusBroadcast[key].name} `}{key !== 'all' && data.stats[key] > 0 && <span className='badge badge-warning badge-pill'>{toRupiah(data.stats[key])}</span>}
                          </button>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
              <pre className={`alert ${redux.darkModeOn ? 'alert-dark' : 'alert-light'} rounded-0 mb-0`}>
                {LangBroadcastInfo}
              </pre>
              {control.showLoad
                ? SpinnerLoadFirst
                : (
                  <div className='table-responsive'>
                    <table className='table table-striped table-bordered'>
                      <thead>
                        <tr>
                          <th className='text-center align-middle' scope='col' style={{ minWidth: '150px' }}>{LangTitle}</th>
                          <th className='text-center align-middle' scope='col' style={{ minWidth: '150px' }}>{LangCreator}</th>
                          <th className='text-center align-middle' scope='col' style={{ minWidth: '150px' }}>{LangReceiver}</th>
                          <th className='text-center align-middle' scope='col' style={{ minWidth: '150px' }}>Status</th>
                          <th className='text-center align-middle' scope='col' style={{ minWidth: '150px' }}>{Action}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data.data?.map((item, i) => (
                            <React.Fragment key={item.id}>
                              <tr>
                                <td>{item.message_title}</td>
                                <td>
                                  {item.created_by && (
                                    <>
                                      <div className='media align-items-center'>
                                        <ShowImage className='mr-3 img-40 rounded-circle' url={null} defaultpictureonload={1} />
                                        <div className='media-body'>
                                          <h6 className='mb-0 font-weight-normal'>{item.created_by.name}</h6>
                                          <p data-tip={CreatedAt} className='mb-0'>{displayDate(item.created_at, 'DD MMM YYYY')}</p>
                                        </div>
                                      </div>
                                      <ReactTooltip place='bottom' />
                                    </>
                                  )}
                                </td>
                                <td>
                                  <h6>{categoryReciver.filter(type => type.value === item.target_type)[0].label}</h6>
                                  <div className='customers'>
                                    <ul>
                                      {
                                        item.target_count !== 0 && (
                                          item.target_detail.map((subItem, i) => (
                                            <li key={i} className='d-inline-block'>
                                              {
                                                (item.target_type === 'all' || item.target_type === 'personnels')
                                                  ? (
                                                    <ShowImage data-tip={subItem.name} className='img-30 rounded-circle' url={subItem.picture_url.s ? subItem.picture_url.s : null} defaultpictureonload={1} />
                                                    )
                                                  : (item.target_type === 'groups')
                                                      ? (
                                                        <ShowImage data-tip={subItem.name} className='img-30 rounded-circle' url={null} defaultpictureonload={1} />
                                                        )
                                                      : null
                                              }
                                            </li>
                                          ))
                                        )
                                      }
                                      <li className={`d-inline-block ${item.target_count !== 0 ? 'ml-2' : ''}`}>
                                        {
                                          (item.target_count !== 0 || item.except_id.length !== 0)
                                            ? (
                                              <a style={{ cursor: 'pointer' }} className='btn-link' onClick={() => { handleModalReciver(item) }}>
                                                {(item.target_count !== 0) ? `+${item.target_count} ${LangReceiver}` : LangOtherReceiver}
                                              </a>
                                              )
                                            : LangNoReceiver
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                  <ReactTooltip place='bottom' />
                                </td>
                                <td>
                                  {
                                    item.load
                                      ? <div className='text-center'>{SpinnerButton}</div>
                                      : (
                                        <>
                                          <h6 className='mb-0'><span className={`badge badge-${statusBroadcast[item.status].color} font-weight-normal`}>{statusBroadcast[item.status].name}</span></h6>
                                          <p data-tip={CreatedAt} className='mb-0'>{displayDate(item.created_at, 'DD MMM YYYY')}</p>
                                        </>
                                        )
                                  }
                                </td>
                                <td>
                                  {item.load
                                    ? <div className='text-center'>{SpinnerButton}</div>
                                    : (
                                      <>
                                        {/* Hide action set when status is 'done' */}
                                        {item.status !== 'done' && (
                                          <>
                                            {item.status === 'setup'
                                              ? (
                                                <>
                                                  {/* Action set when status is 'setup' */}
                                                  {checkAuthorization(redux, 'broadcast.update') && (
                                                    <a className='btn-light btn-sm mr-2 mb-2' data-tip={Edit} style={{ cursor: 'pointer', display: 'inline-block' }} onClick={() => { handleForm(item) }}><FontAwesomeIcon color={redux.darkModeOn ? 'white' : null} icon={faPen} /></a>
                                                  )}
                                                  {(item.status === 'setup' || item.status === 'paused') && checkAuthorization(redux, 'broadcast.update') && <a data-tip={LangTestSend} className='btn-light btn-sm mr-2 mb-2' style={{ cursor: 'pointer' }} onClick={() => { handleTestSend(item) }}><FontAwesomeIcon color={redux.darkModeOn ? 'white' : null} icon={faShare} /></a>}
                                                  {checkAuthorization(redux, 'broadcast.update') && (
                                                    <a
                                                      style={{ cursor: 'pointer' }}
                                                      onClick={() => {
                                                        if (item.status !== 'done') handleStatusChange((item.status !== 'sending') ? 'sending' : 'paused', item.id, i)
                                                      }}
                                                      data-tip={item.status !== 'sending' ? LangSend : LangPause}
                                                      className={`${(item.status !== 'sending') ? 'btn-primary' : 'btn-info'} btn-sm mr-2 mb-2`}
                                                    >
                                                      <FontAwesomeIcon icon={item.status !== 'sending' ? faPaperPlane : faPause} />
                                                    </a>
                                                  )}
                                                </>
                                                )
                                              : (
                                                <>
                                                  {/* Action set when status except 'setup' */}
                                                  {checkAuthorization(redux, 'broadcast.update') && (
                                                    <a
                                                      style={{ cursor: item.status !== 'done' ? 'pointer' : 'default' }}
                                                      onClick={() => {
                                                        if (item.status !== 'done') handleStatusChange((item.status !== 'sending') ? 'sending' : 'paused', item.id, i)
                                                      }}
                                                      data-tip={item.status !== 'done' ? item.status !== 'sending' ? LangSend : LangPause : ''}
                                                      className={`${(item.status === 'done') ? 'btn-light' : (item.status !== 'sending') ? 'btn-primary' : 'btn-info'} btn-sm mr-2 mb-2`}
                                                    >
                                                      <FontAwesomeIcon icon={item.status !== 'sending' ? faPaperPlane : faPause} />
                                                    </a>
                                                  )}
                                                  {checkAuthorization(redux, 'broadcast.update') && (
                                                    <a
                                                      style={{ cursor: item.status !== 'done' ? 'pointer' : 'default' }}
                                                      data-tip={item.status !== 'done' ? Cancel : ''}
                                                      onClick={() => {
                                                        if (item.status !== 'done') { handleStatusChange('canceled', item.id, i) }
                                                      }}
                                                      className={`${item.status !== 'done' ? 'btn-danger' : 'btn-light'}  btn-sm mr-2 mb-2`}
                                                    >
                                                      <FontAwesomeIcon icon={faTimes} />
                                                    </a>
                                                  )}
                                                </>)}

                                          </>
                                        )}
                                        <a style={{ cursor: 'pointer' }} id='moreDropdown' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' className='btn-light btn-sm mr-2 mb-2'><FontAwesomeIcon color={redux.darkModeOn ? 'white' : null} icon={faEllipsisH} /></a>
                                        <div className='dropdown-menu shadow' aria-labelledby='moreDropdown'>
                                          {item.status === 'setup'
                                            ? (
                                              <>
                                                {/* Action set when status is 'setup' */}
                                                {checkAuthorization(redux, 'broadcast.update') && (
                                                  <a className='dropdown-item' style={{ cursor: 'pointer' }} onClick={() => { copyBroadcastService(item, i) }}>{LangCopy}</a>
                                                )}
                                                {checkAuthorization(redux, 'broadcast.update') && (
                                                  <a className='dropdown-item' style={{ cursor: 'pointer' }} onClick={() => { handleStatusChange('canceled', item.id, i) }}>{Cancel}</a>
                                                )}
                                                {checkAuthorization(redux, 'broadcast.delete') && (
                                                  <a className='dropdown-item text-danger' style={{ cursor: 'pointer' }} onClick={() => { handleDelete(item) }}>{Delete}</a>
                                                )}
                                              </>
                                              )
                                            : (
                                              <>
                                                {/* Action set when status except 'setup' */}
                                                {(item.status === 'done') && <a className='dropdown-item' style={{ cursor: 'pointer' }} onClick={() => { handlePreview(item) }}>{LangViewContent}</a>}
                                                {checkAuthorization(redux, 'broadcast.update') && (
                                                  <>
                                                    {(item.status === 'paused') && <a className='dropdown-item' style={{ cursor: 'pointer' }} onClick={() => { handleTestSend(item) }}>{LangTestSend}</a>}
                                                    <a className='dropdown-item' style={{ cursor: 'pointer' }} onClick={() => { copyBroadcastService(item, i) }}>{LangCopy}</a>
                                                    {item.status !== 'done' && <a className='dropdown-item' style={{ cursor: 'pointer' }} onClick={() => { handleForm(item) }}>{Edit}</a>}
                                                  </>
                                                )}
                                                {checkAuthorization(redux, 'broadcast.delete') && (
                                                  <a className='dropdown-item text-danger' style={{ cursor: 'pointer' }} onClick={() => { handleDelete(item) }}>{Delete}</a>
                                                )}
                                              </>
                                              )}
                                          {/* <a className='dropdown-item' style={{ cursor: 'pointer' }} onClick={() => { history.push(toActivityLogs('Broadcast', item?.id)) }}>{ChangeHistories}</a> */}
                                          <a className='dropdown-item' style={{ cursor: 'pointer' }} onClick={() => { setControl({ ...control, showModalActivity: true }); setCurrentData(item) }}>{ChangeHistories}</a>
                                        </div><ReactTooltip place='bottom' />
                                      </>
                                      )}

                                </td>
                              </tr>
                              {(item.alert.show) && (
                                <tr>
                                  <td className='p-0' colSpan={5}>
                                    <Alert className='m-0' variant={item.alert.variant} onClose={() => { handleAlertTable(i) }} dismissible>
                                      {item.alert.message} {item.alert.code === 404 && <a style={{ cursor: 'pointer' }} className='btn-sm btn-light' onClick={() => { getBroadcastsService() }}><FontAwesomeIcon icon={faSync} /></a>}
                                    </Alert>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                  )}
              <div className='card-footer'>
                <PaginationDiv data={data} onChange={handlePage} />
                {data?.error?.message && <div class='alert alert-danger my-2 w-100'>{data?.error?.message}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>

      {(control.showModalActivity) && (
        <ModalApp
          title={ChangeHistories}
          size='xl'
          handleClose={() => setControl({ ...control, showModalActivity: false })}
          body={<ModalAdminActivityLogsComponent type='Broadcast' id={currentData.id} />}
        />
      )}

      {(control.showModalPreview) && (
        <ModalApp
          title='Detail Broadcast'
          handleClose={() => setControl({ ...control, showModalPreview: false })}
          body={<BroadcastPreviewComponent data={{ ...currentData }} />}
        />
      )}

      {(control.showModalTestSend) && (
        <ModalApp
          title={`${LangTestSend} Broadcast`}
          handleClose={() => setControl({ ...control, showModalTestSend: false })}
          body={<BroadcastTestSendComponent id={currentData.id} onClose={() => setControl({ ...control, showModalTestSend: false })} />}
        />
      )}

      {(control.showModalQuota) && (
        <ModalApp
          title={LangOrderAddQuotaBroadcast}
          handleClose={() => setControl({ ...control, showModalQuota: false })}
          body={
            <BroadcastQuotaComponent onUpdate={(summary) => {
              setControl({ ...control, showModalQuota: false })
              setOrderSummary(order => ({ data: summary, showModal: true }))
            }}
            />
          }
        />
      )}

      {(orderSummary.showModal) && (
        <ModalApp
          title={LangPaymentProcess}
          handleClose={() => setOrderSummary(order => ({ ...order, showModal: false }))}
          body={
            <OrderSummaryComponent
              orderType='broadcast'
              orderText=' Order Penambahan Kuota Broadcast'
              data={orderSummary.data}
              setCloseModal={() => setOrderSummary(order => ({ ...order, showModal: false }))}
            />
          }
        />
      )}

      <ModalAction
        style={{ marginTop: '15%' }}
        title={LangCancelConfirmation}
        show={control.showModalCancel}
        closeButton={() => setControl({ ...control, showModalCancel: false })}
        onHide={() => setControl({ ...control, showModalCancel: false })}
        body={
          <>
            <button disabled={control.showLoad} className='btn  btn-danger mr-2' type='button' onClick={() => statusBroadcastService()}>
              {(control.showLoad) ? SpinnerButton : ''}{Yes}
            </button>
            <button className='btn btn-primary mr-2' type='button' onClick={() => setControl({ ...control, showModalCancel: false })}>{No}</button>
          </>
        }
      />

      <ModalDelete
        show={control.showModalDelete}
        onHide={() => setControl({ ...control, showModalDelete: false, onSubmitDeleteResponse: {} })}
        closeButton={() => setControl({ ...control, showModalDelete: false, onSubmitDeleteResponse: {} })}
        btn={
          <>
            {control.onSubmitDeleteResponse?.message && <div class='alert alert-danger my-2 w-100'>{control.onSubmitDeleteResponse?.message}</div>}
            {control.onSubmitDeleteResponse?.success && <div class='alert alert-success my-2 w-100'>{LangDeleteSuccess}</div>}
            {(!control.onSubmitDeleteResponse?.message && !control.onSubmitDeleteResponse?.success) && (
              <>
                <button disabled={control.showLoad} className='btn  btn-danger mr-2' type='button' onClick={() => deleteBroadcastService()}> {(control.showLoad) ? SpinnerButton : ''}{Yes}</button>
                <button className='btn  btn-primary mr-2' type='button' onClick={() => setControl({ ...control, showModalDelete: false, onSubmitDeleteResponse: {} })}>{No}</button>
              </>
            )}
          </>
        }
      />
      {(control.showModalReciver) && (
        <ModalApp
          title={LangBroadcastReceiver}
          size='lg'
          handleClose={() => setControl({ ...control, showModalReciver: false })}
          body={<BroadcastReceiverComponent broadcastData={currentData} />}
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps)(BroadcastComponent)
