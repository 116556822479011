import React, { lazy } from 'react'
import { connect, mapStateToProps, Route } from '../helpers/Common'

const ManageSubAdmin = lazy(() => import('../components/sub-admin/SubAdminComponent'))
const ManageRole = lazy(() => import('../components/sub-admin/ManageRoleComponent'))
const ManageRoleForm = lazy(() => import('../components/sub-admin/ManageRoleFormComponent'))

function SubAdmin({ redux }) {
  if (redux.profile.role_id !== 4) {
    return (
      <>
        <Route exact path='/sub-admin' component={ManageSubAdmin} />
        <Route exact path='/sub-admin/role-management' component={ManageRole} />
        <Route exact path='/sub-admin/role-management/add' component={ManageRoleForm} />
        <Route exact path='/sub-admin/role-management/edit/:id' component={ManageRoleForm} />
      </>
    )
  }
  return <></>
}

export default connect(mapStateToProps)(SubAdmin)