import React from 'react'
import {
  connect,
  mapStateToProps,
  SpinnerButton,
  SpinnerLoadFirst,
  moment,
  axiosCompany,
  toRupiah,
  getDivisionClientVisit
} from '../../helpers/Common'
import {
  Division,
  Close,
  LangFilterGroup,
  LangTargetCount,
  LangEmployee,
  LangProcess,
  LangMonth
} from '../../constant/languange'
import { Success } from '../../helpers/Message'

class VisitScheduleExportComponent extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      loadFirst: true,
      totalTarget: props.totalTarget || 0,
      groups: props.redux.groups,
      groupId: props.groupId || '',
      currentMonth: props.currentMonth,
      currentYear: props.currentYear,
      errors: {},
      success: false,
      errorMessage: null,
      onExportResponse: {}
    }
  }

  // subscribe props traditional react component
  static getDerivedStateFromProps (props, state) {
    const setState = {}
    if (props.totalTarget !== state.totalTarget) {
      setState.totalTarget = props.totalTarget
    }

    if (+props.groupId !== +state.groupId) {
      setState.groupId = props.groupId
    }

    if (props.currentMonth !== state.currentMonth) {
      setState.currentMonth = props.currentMonth
    }

    if (props.currentYear !== state.currentYear) {
      setState.currentYear = props.currentYear
    }

    return setState === {} ? null : setState
  }

  componentDidMount () {
    // this.setState({ loadFirst: false })
    getDivisionClientVisit()
      .then(data => this.setState({ loadFirst: false, groups: data }))
      .catch((err) => {
        this.setState({ loadFirst: false, groups: [] })
      })
  }

  handleSubmit (e) {
    e.preventDefault()

    this.setState({ onSubmit: true, errors: {}, errorMessage: null, onExportResponse: {} })

    const formData = new window.FormData()
    formData.append('group_id', this.state.groupId)
    formData.append('month', this.state.currentMonth)
    formData.append('year', this.state.currentYear)

    axiosCompany()
      .post('client-visit-schedules/export-excel', formData, { responseType: 'blob' })
      .then((result) => {
        /**
         * pengaturan nama file
         */
        let namePrefix = ''
        if (this.state.groupId) {
          const group = this.state.groups.find((item) => +item.id === +this.state.groupId)
          namePrefix += `${group.name}-`
        }
        namePrefix += `${this.state.currentMonth}-${this.state.currentYear}`

        const url = window.URL.createObjectURL(new window.Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `visit-schedule-${namePrefix}.xlsx`)
        document.body.appendChild(link)
        link.click()

        this.setState({
          onSubmit: false,
          errors: {}
        })
      })
      .catch(async (error) => {
        if (error.response.data.errors) {
          this.setState({ errors: error.response.data.errors })
        } else {
          this.setState({ errors: { 0: [error.response.statusText] } })
        }
        if (error.response.data.message) {
          this.setState({ errorMessage: error.response.data.message })
        }
        if (
          error.request.responseType === 'blob' &&
          error.response.data instanceof Blob &&
          error.response.data.type &&
          error.response.data.type.toLowerCase().indexOf('json') !== -1
        ) {
          let errorMsg = error.response.data
          errorMsg = JSON.parse(await error.response.data.text())
          this.setState({ onExportResponse: errorMsg })
        }
        this.setState({ onSubmit: false })
      })
  }

  render () {
    const s = this.state

    if (s.loadFirst) return SpinnerLoadFirst

    return (
      <form onSubmit={(e) => this.handleSubmit(e)}>
        <div className='form-group row'>
          <label className='col-sm-3 col-form-label'>{LangFilterGroup}</label>
          <div className='col-sm-9'>
            <select className='form-control' value={s.groupId} onChange={(e) => this.props.onChangeGroup(e.target.value)}>
              <option value=''>--{Division}--</option>
              {s.groups.map((obj) => {
                return (
                  <option key={obj.id} value={obj.id}>{obj.name}</option>
                )
              })}
            </select>
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-sm-3 col-form-label'>{LangTargetCount}</label>
          <div className='col-sm-9 pt-1'>
            {toRupiah(s.totalTarget)} {LangEmployee}
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-sm-3 col-form-label'>{LangMonth}</label>
          <div className='col-sm-9'>
            <div className='d-flex flex-row'>
              <div className='mr-2'>
                <select onChange={(e) => this.props.onChangeMonth(e.target.value)} value={s.currentMonth} className='form-control'>
                  {moment.months().map((item, index) => (
                    <option value={index + 1} key={index}>{item}</option>
                  ))}
                </select>
              </div>
              <div className='mr-2'>
                <select onChange={(e) => this.props.onChangeYear(e.target.value)} value={s.currentYear} className='form-control'>
                  <option value={moment().subtract(1, 'years').format('YYYY')}>{moment().subtract(1, 'years').format('YYYY')}</option>
                  <option value={moment().format('YYYY')}>{moment().format('YYYY')}</option>
                  <option value={moment().add(1, 'years').format('YYYY')}>{moment().add(1, 'years').format('YYYY')}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className='form-group row'>
          <div className='col-sm-9 offset-3'>
            {s.onExportResponse?.message && <div class='alert alert-danger'>{s.onExportResponse?.message}</div>}
            {s.success ? <Success /> : null}
            {s.errorMessage ? <div className='alert alert-danger'> <p style={{ fontSize: 18, textAlign: 'center' }}>{s.errorMessage}</p></div> : null}

            <button disabled={s.onSubmit || s.totalTarget === 0} className='btn  btn-primary mr-2' onClick={this.handleSubmitEdit}> {(s.onSubmit) ? SpinnerButton : ''} {LangProcess}</button>
            <button disabled={s.onSubmit} className='btn  btn-light' type='button' onClick={this.props.onClose}>
              {Close}
            </button>
          </div>
        </div>
      </form>
    )
  }
}

export default connect(mapStateToProps)(VisitScheduleExportComponent)
